import React, { useState } from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import { useTranslation } from '../utils/language';
import SecureAudioPlayer from './SecureAudioPlayer';
import YouTubeEmbed from '../components/YouTubeEmbed';

const MusicianCredit = ({ instrument, name }) => (
  <div className="text-sm text-gray-300">
    <span className="font-medium text-amber-500">{instrument}:</span> {name}
  </div>
);

const Composition = () => {
  const { language } = useLanguage();
  const { t } = useTranslation(language);
  const [activeCategory, setActiveCategory] = useState('all');

  const compositions = [
    {
      id: 'donna-steps',
      category: 'jazz',
      title: 'Donna steps',
      description: t('composition.descriptions.donnaSteps'),
      type: 'video',
      videoId: 'uHMsdIAQ--8',
      musicians: []
    },
    {
      id: 'acordeonita',
      category: 'jazz',
      title: 'Acordeonita',
      type: 'video',
      videoId: 'ZPdzzeIWHiQ',
      musicians: [
        { 
          instrument: t('composition.instruments.accordion'), 
          name: 'Sara Formoso' 
        },
        { 
          instrument: t('composition.instruments.drums'), 
          name: 'Yogev Gabay' 
        },
        { 
          instrument: t('composition.instruments.bass'), 
          name: 'Régis Lamora' 
        }
      ]
    },
    {
      id: 'memento-mori',
      category: 'classical',
      title: 'MEMENTO MORI',
      type: 'video',
      videoId: 'Sy5HnYJ2lSo',
      musicians: [
        { 
          instrument: t('composition.instruments.accordion'), 
          name: 'Sara Formoso' 
        }
      ]
    },
    {
      id: 'la-question',
      category: 'classical',
      title: 'La question',
      description: t('composition.descriptions.laQuestion'),
      type: 'video',
      videoId: 'CHWNLrIsmDQ',
      musicians: []
    },
    {
      id: 'les-temps-changent',
      category: 'classical',
      title: 'Les temps changent',
      type: 'video',
      videoId: 'Q6FZJp197ls',
      musicians: [
        { 
          instrument: t('composition.instruments.violin'), 
          name: 'Aude Pesquier' 
        }
      ]
    },
    {
      id: 'gare-de-bois-colombes',
      category: 'classical',
      title: 'La gare de Bois-Colombes',
      description: t('composition.descriptions.gareBoisColombes'),
      type: 'audio',
      tracks: [
        {
          title: 'La Gare de Bois-Colombes',
          url: 'https://dtlck96hf0n6q.cloudfront.net/audio/La+Gare+de+Bois-Colombes+-+Re%CC%81gis+Lamora.mp3',
          imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/La%2BGare%2Bde%2BBois-Colombes.jpg'
        }
      ],
      musicians: []
    },
    {
      id: 'menoleaditation',
      category: 'world',
      title: 'Menoleaditation',
      type: 'audio',
      tracks: [
        {
          title: 'Menoleaditation',
          url: 'https://dtlck96hf0n6q.cloudfront.net/audio/Menoleaditation+-+Re%CC%81gis+Lamora.mp3',
          imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/Menoleaditation.jpg'
        }
      ],
      musicians: []
    }
  ];

  const categories = [
    { id: 'all', name: { en: 'All', fr: 'Tout', es: 'Todo' } },
    { id: 'jazz', name: { en: 'Jazz', fr: 'Jazz', es: 'Jazz' } },
    { id: 'classical', name: { en: 'Classical', fr: 'Classique', es: 'Clásico' } },
    { id: 'world', name: { en: 'World music', fr: 'World music', es: 'World music' } }
  ];

  return (
    <div className="max-w-4xl mx-auto text-white">
      <div className="mb-8">
        <div className="flex flex-wrap gap-2">
          {categories.map(category => (
            <button
              key={category.id}
              onClick={() => setActiveCategory(category.id)}
              className={`px-4 py-2 rounded-full text-sm font-medium transition-colors
                ${activeCategory === category.id 
                  ? 'bg-amber-500 text-black' 
                  : 'bg-orange-50/10 text-white hover:bg-orange-50/20'
                }`}
            >
              {category.name[language]}
            </button>
          ))}
        </div>
      </div>

      <div className="grid gap-8">
        {compositions.map(composition => (
          <div 
            key={composition.id}
            className={`bg-orange-50/5 rounded-lg overflow-hidden hover:bg-orange-50/10 transition-colors
              ${activeCategory !== 'all' && composition.category !== activeCategory ? 'hidden' : ''}`}
          >
            <div className="p-6">
              <h2 className="text-2xl font-bold mb-3 text-amber-500">
                {composition.title}
              </h2>
              
              {composition.description && (
                <p className="text-gray-300 mb-4">
                  {composition.description}
                </p>
              )}

              {composition.type === 'video' ? (
                <YouTubeEmbed videoId={composition.videoId} />
              ) : (
                <SecureAudioPlayer 
                  tracks={composition.tracks}
                />
              )}

              {composition.musicians.length > 0 && (
                <div className="mt-4 space-y-1">
                  {composition.musicians.map((musician, index) => (
                    <MusicianCredit 
                      key={index}
                      instrument={musician.instrument}
                      name={musician.name}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Composition;