import React from 'react';
import { useLocation } from 'react-router-dom';
// Import everything as a namespace
import * as ReactHelmetAsync from 'react-helmet-async';
const { Helmet } = ReactHelmetAsync;
import { AVAILABLE_LANGUAGES } from '../utils/translations';

/**
 * Component for handling SEO metadata using React Helmet Async
 * 
 * @param {string} title - Page title
 * @param {string} description - Page meta description
 */
const SEOHead = ({ title, description }) => {
  const location = useLocation();
  const currentPath = location.pathname.split('/').slice(2).join('/'); // Remove language from URL
  const baseUrl = 'https://regislamora.com'; 

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      
      {/* Hreflang tags for each available language */}
      {AVAILABLE_LANGUAGES.map(lang => (
        <link 
          key={lang}
          rel="alternate"
          hreflang={lang}
          href={`${baseUrl}/${lang}/${currentPath}`}
        />
      ))}
      
      {/* Default version for users with other languages */}
      <link 
        rel="alternate"
        hreflang="x-default"
        href={`${baseUrl}/en/${currentPath}`}
      />
    </Helmet>
  );
};

export default SEOHead;