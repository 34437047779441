import React from 'react';
import { getAssetPath } from '../utils/paths';
import { Outlet } from 'react-router-dom';
import Navigation from './Navigation';
import Newsletter from './Newsletter';
import Footer from './Footer';

const Layout = () => {
  return (
    <div className="min-h-screen bg-dark-bg text-gray-100 flex flex-col font-cabin">
      <header className="sticky top-0 w-full z-50 bg-black">
        <div className="container mx-auto">
          <div className="w-full max-w-[1024px] mx-auto aspect-[2048/400]">
            <img 
              src={getAssetPath('/images/banner/banner.jpg')}
              alt="Régis Lamora"
              className="w-full h-full object-cover"
            />
          </div>
          <Navigation />
        </div>
      </header>
      <main className="container mx-auto px-4 py-8 flex-grow">
        <Outlet />
      </main>
      <Newsletter />
      <Footer />
    </div>
  );
};

export default Layout;