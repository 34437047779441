import React from 'react'
import ReactDOM from 'react-dom/client'
import { getInitialLanguage } from './utils/language'
import { createApp } from './createApp'
import './index.css'

// Client-side code only (browser)
if (typeof window !== 'undefined' && !import.meta.env.SSR) {
  const container = document.getElementById('root')
  
  if (container) {
    // Check if the page was prerendered
    const isPrerendered = window.__PRERENDERED__;
    
    // Options for app creation
    const options = {
      initialLanguage: getInitialLanguage()
    };
    
    if (isPrerendered) {
      // For prerendered content, use hydrateRoot to preserve server-generated DOM
      ReactDOM.hydrateRoot(container, createApp(options));
    } else {
      // Otherwise, do a fresh render
      const root = ReactDOM.createRoot(container);
      root.render(createApp(options));
    }
  }
}

// For SSG - Export a function that creates the app
export default function(context) {
  return createApp({ helmetContext: context });
}