import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from '../utils/language';
import { useLanguage } from '../contexts/LanguageContext';
import { getRoutePath } from '../utils/paths';

const NotFound = () => {
  const { language } = useLanguage();
  const { t } = useTranslation(language);
  const { lang } = useParams();

  return (
    <div className="flex flex-col items-center justify-center min-h-[50vh]">
      <h1 className="text-4xl font-bold text-amber-500 mb-4">404</h1>
      <p className="text-xl mb-8">{t('errors.pageNotFound')}</p>
      <Link 
        to={getRoutePath(`${lang}`)} 
        className="text-amber-500 hover:text-amber-400 transition-colors"
      >
        {t('errors.backToHome')}
      </Link>
    </div>
  );
};

export default NotFound;