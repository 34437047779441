import React, { createContext, useContext } from 'react';
import { useTranslation } from '../utils/language';

const LanguageContext = createContext();

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};

export const LanguageProvider = ({ children, initialLanguage, setLanguage }) => {
  const { t } = useTranslation(initialLanguage);

  return (
    <LanguageContext.Provider 
      value={{ 
        language: initialLanguage, 
        setLanguage,
        t 
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};