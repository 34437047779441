// src/utils/paths.js

/**
 * Génère un chemin correctement formaté pour les assets statiques
 * en tenant compte de la base URL de l'environnement courant.
 * 
 * @param {string} path - Le chemin relatif de l'asset (avec ou sans "/" initial)
 * @returns {string} - Le chemin complet incluant la base URL si nécessaire
 */
export const getAssetPath = (path) => {
    // Récupération de la base URL depuis les variables d'environnement
    const baseUrl = import.meta.env.VITE_BASE_URL || '';
    
    // Si le chemin est déjà une URL absolue complète (http:// ou https://), la retourner telle quelle
    if (path.startsWith('http://') || path.startsWith('https://')) {
      return path;
    }
    
    // Normaliser le path pour éviter les doubles slashes
    const normalizedPath = path.startsWith('/') ? path.substring(1) : path;
    const normalizedBase = baseUrl.endsWith('/') ? baseUrl : baseUrl + '/';
    
    // Si baseUrl est vide, ne pas ajouter de slash supplémentaire
    if (normalizedBase === '/') {
      return `/${normalizedPath}`;
    }
    
    return `${normalizedBase}${normalizedPath}`;
  };
  
  /**
   * Version simplifiée qui prend en charge les chemins d'URL pour le routeur
   * (sans ajouter de slash à la fin)
   * 
   * @param {string} path - Le chemin de route (avec ou sans "/" initial)
   * @returns {string} - Le chemin complet incluant la base URL si nécessaire
   */
  export const getRoutePath = (path) => {
    const baseUrl = import.meta.env.VITE_BASE_URL || '';
    
    // Si le chemin est déjà une URL absolue, la retourner telle quelle
    if (path.startsWith('http://') || path.startsWith('https://')) {
      return path;
    }
    
    // Normaliser le path pour éviter les doubles slashes
    const normalizedPath = path.startsWith('/') ? path.substring(1) : path;
    
    // Si baseUrl est vide, ajouter un slash au début si nécessaire
    if (!baseUrl) {
      return normalizedPath ? `/${normalizedPath}` : '/';
    }
    
    const normalizedBase = baseUrl.endsWith('/') ? baseUrl.slice(0, -1) : baseUrl;
    return normalizedPath ? `${normalizedBase}/${normalizedPath}` : normalizedBase;
  };