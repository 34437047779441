import React, { useState } from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import { useTranslation } from '../utils/language';
import MathCaptcha from '../components/ui/MathCaptcha';
import SEOHead from '../components/SEOHead';

const ContactForm = () => {
  const { language } = useLanguage();
  const { t } = useTranslation(language);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  
  const [status, setStatus] = useState({
    type: '',
    message: ''
  });

  const [isCaptchaValid, setIsCaptchaValid] = useState(false);

  const handleCaptchaValidation = (isValid) => {
    setIsCaptchaValid(isValid);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isCaptchaValid) {
      setStatus({
        type: 'error',
        message: t('contact.captchaRequired')
      });
      return;
    }

    const data = {
      access_key: import.meta.env.VITE_WEB3FORMS_KEY,
      ...formData,
      from_name: formData.name,
      subject: `Contact depuis le site - ${formData.name}`,
      language,
      site: window.location.hostname,
      human_verified: isCaptchaValid
    };

    try {
      setStatus({ type: 'info', message: t('contact.sending') });

      const response = await fetch('https://api.web3forms.com/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      });

      const result = await response.json();

      if (result.success) {
        setStatus({
          type: 'success',
          message: t('contact.success')
        });
        setFormData({
          name: '',
          email: '',
          message: ''
        });
        setIsCaptchaValid(false);
      } else {
        throw new Error(t('contact.error'));
      }
    } catch (error) {
      setStatus({
        type: 'error',
        message: t('contact.error')
      });
    }
  };

  return (
    <>
      <SEOHead
        title={t('seo.contact.title')}
        description={t('seo.contact.description')}
      />
      <div className="max-w-2xl mx-auto">
        <div className="bg-dark-surface rounded-lg shadow-lg p-8">
          <h1 className="text-2xl font-bold mb-6 text-amber-500">{t('contact.title')}</h1>
          
          <form onSubmit={handleSubmit} className="space-y-6">
            <input type="checkbox" name="botcheck" className="hidden" style={{ display: 'none' }} />
            
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-100 mb-1">
                {t('contact.name')}
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-amber-500 bg-dark-surface text-gray-100 border-gray-600"
              />
            </div>

            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-100 mb-1">
                {t('contact.email')}
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-amber-500 bg-dark-surface text-gray-100 border-gray-600"
              />
            </div>

            <div>
              <label htmlFor="message" className="block text-sm font-medium text-gray-100 mb-1">
                {t('contact.message')}
              </label>
              <textarea
                id="message"
                name="message"
                rows={6}
                value={formData.message}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-amber-500 bg-dark-surface text-gray-100 border-gray-600"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-100 mb-2">
                {t('contact.verifyHuman')}
              </label>
              <MathCaptcha 
                onValidate={handleCaptchaValidation}
                translations={{
                  new: t('contact.newCaptcha'),
                  placeholder: t('contact.enterResult')
                }}
              />
            </div>

            {status.message && (
              <div className={`p-4 rounded-lg ${
                status.type === 'success' ? 'bg-green-50 text-green-800' :
                status.type === 'error' ? 'bg-red-50 text-red-800' :
                'bg-blue-50 text-blue-800'
              }`}>
                {status.message}
              </div>
            )}

            <button
              type="submit"
              disabled={!isCaptchaValid}
              className={`w-full py-3 px-6 rounded-lg transition-colors ${
                isCaptchaValid
                  ? 'bg-amber-500 text-black hover:bg-amber-600'
                  : 'bg-gray-500 text-gray-300 cursor-not-allowed'
              }`}
            >
              {status.type === 'info' ? t('contact.sending') : t('contact.submit')}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactForm;