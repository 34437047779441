import React, { createContext, useContext } from 'react';

const AudioPlayerContext = createContext();

export function AudioPlayerProvider({ children }) {
  const stopAllPlayers = (exceptPlayer) => {
    const players = document.getElementsByTagName('audio');
    Array.from(players).forEach(player => {
      if (player !== exceptPlayer) {
        player.pause();
      }
    });
  };

  return (
    <AudioPlayerContext.Provider value={{ stopAllPlayers }}>
      {children}
    </AudioPlayerContext.Provider>
  );
}

export const useAudioPlayer = () => useContext(AudioPlayerContext);