import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useLanguage } from '../contexts/LanguageContext';
import { useTranslation } from '../utils/language';

// Section Header Component
const SectionHeader = ({ title, isOpen, onClick }) => (
  <button
    onClick={onClick}
    className="section-header flex justify-between items-center w-full"
  >
    <h2 className="text-xl font-bold text-amber-500">{title}</h2>
    <span className={`transform transition-transform ${isOpen ? 'rotate-180' : ''} text-gray-100 ml-4`}>
      ▼
    </span>
  </button>
);

// Experience Item Component
const ExperienceItem = ({ title, details }) => (
  <div className="mb-4 last:mb-0">
    <h3 className="font-semibold text-amber-400">{title}</h3>
    <ul className="mt-2 space-y-2">
      {details.map((detail, index) => (
        <li key={index} className="text-gray-100 pl-4 whitespace-pre-line">
          • {React.isValidElement(detail) ? detail : detail}
        </li>
      ))}
    </ul>
  </div>
);

const Career = () => {
  const { language } = useLanguage();
  const { t } = useTranslation(language);
  const navigate = useNavigate();
  const { lang } = useParams();
  const [openSections, setOpenSections] = useState({
    scene: false,
    experience: false,
    teaching: false,
    formation: false
  });

  // Translation helper for instruments
  const getFormattedSceneDetails = () => {
    const instrumentMap = {
      fr: { b: 'b', kbd: 'clav', gt: 'gt', cht: 'cht' },
      en: { b: 'bass', kbd: 'keys', gt: 'gtr', cht: 'voc' },
      es: { b: 'bajo', kbd: 'tecl', gt: 'gtr', cht: 'voz' }
    };
    
    const formatInstruments = (text) => {
      const map = instrumentMap[language];
      Object.entries(map).forEach(([key, value]) => {
        text = text.replace(new RegExp(`\\b${key}\\b`, 'g'), value);
      });
      return text;
    };

    const sceneDetails = {
      fr: {
        concerts: [
          'Alegría in a new light (Cirque du Soleil - b, kbd)',
          'Spookla (gt-b-cht)',
          'Lucas (gt-b-cht)',
          'Paco Koné (gt)',
          'Dye & the Mellowz (b)',
          'Opening (b)',
          "Mister Oz's Big Band (gt)",
          'Le Tourdion (gt-b)',
          'Orchestres de variétés (gt-b)'
        ],
        tv: [
          'Plateaux TV avec le Fred Manoukian Big Band ("Tenue de Soirée" et "Le Tour en Fête", Fr.2)\nArtistes accompagnés: Christophe Willem, Amel Bent, Patrick Bruel, Christophe Maé, Liane Foly, Laurent Gerra, Chimène Badi, Julie Zenatti, Dave, Michène Torr, Carla Bruni, Christian Morin, Philippe Lavil, Jean-Patrick Capdevielle...'
        ],
        collaborations: [
          'À la guitare: Guy Marchand (avec le Fred Manoukian Small Band), Lio, Jeanne Mas, Toubab or not Toubab...',
          'À la basse: Marion Corrales, Faster Disco, Pamela Hute, Sawuri, Maria Tejada, Maîtrise vocale de la Cathédrale de Metz...'
        ]
      },
      en: {
        concerts: [
          'Alegría in a new light (Cirque du Soleil - b, kbd)',
          'Spookla (gtr-b-voc)',
          'Lucas (gtr-b-voc)',
          'Paco Koné (gtr)',
          'Dye & the Mellowz (b)',
          'Opening (b)',
          "Mister Oz's Big Band (gtr)",
          'Le Tourdion (gtr-b)',
          'Variety orchestras (gtr-b)'
        ],
        tv: [
          'TV Shows with Fred Manoukian Big Band ("Tenue de Soirée" and "Le Tour en Fête", Fr.2)\nAccompanied artists: Christophe Willem, Amel Bent, Patrick Bruel, Christophe Maé, Liane Foly, Laurent Gerra, Chimène Badi, Julie Zenatti, Dave, Michène Torr, Carla Bruni, Christian Morin, Philippe Lavil, Jean-Patrick Capdevielle...'
        ],
        collaborations: [
          'On guitar: Guy Marchand (with Fred Manoukian Small Band), Lio, Jeanne Mas, Toubab or not Toubab...',
          'On bass: Marion Corrales, Faster Disco, Pamela Hute, Sawuri, Maria Tejada, Metz Cathedral Vocal Ensemble...'
        ]
      },
      es: {
        concerts: [
          'Alegría, bajo una nueva luz (Cirque du Soleil - b, tecl)',
          'Spookla (gtr-b-voz)',
          'Lucas (gtr-b-voz)',
          'Paco Koné (gtr)',
          'Dye & the Mellowz (b)',
          'Opening (b)',
          "Mister Oz's Big Band (gtr)",
          'Le Tourdion (gtr-b)',
          'Orquestas de variedades (gtr-b)'
        ],
        tv: [
          'Programas de TV con Fred Manoukian Big Band ("Tenue de Soirée" y "Le Tour en Fête", Fr.2)\nArtistas acompañados: Christophe Willem, Amel Bent, Patrick Bruel, Christophe Maé, Liane Foly, Laurent Gerra, Chimène Badi, Julie Zenatti, Dave, Michène Torr, Carla Bruni, Christian Morin, Philippe Lavil, Jean-Patrick Capdevielle...'
        ],
        collaborations: [
          'En guitarra: Guy Marchand (con Fred Manoukian Small Band), Lio, Jeanne Mas, Toubab or not Toubab...',
          'En bajo: Marion Corrales, Faster Disco, Pamela Hute, Sawuri, Maria Tejada, Conjunto Vocal de la Catedral de Metz...'
        ]
      }
    };

    return sceneDetails[language];
  };

  const getExperienceDetails = () => {
    return {
      fr: {
        composition: {
          title: 'Composition',
          details: [
            'Cirque Arlette Gruss',
            'Musique contemporaine éditée chez C.P. Editions et Regia Editions (Duos, quatuor, solo)',
            'Musique du film de recrutement de la Force Aérienne de Combat de Marly-Frescaty',
            'Spookla'
          ]
        },
        arrangements: {
          title: 'Arrangements',
          details: [
            'Spookla',
            'Lucas',
            'Guitariste.com "CD Concept"',
            'Equinoxe'
          ]
        },
        studio: {
          title: 'Studio',
          details: [
            'Guitare et basse, voir page discographie'
          ]
        },
        productions: {
          title: 'Réalisations',
          details: [
            'Crédits réalisation: EMI Classic, TMS Productions, Digital Juice, Kavall Records',
            'Réduction piano/chant de l\'album "Uncool" de Bumblefoot pour Jaff',
            'Relevés pour les Nuits de Champagne',
            'Relevés Sacem...'
          ]
        }
      },
      en: {
        composition: {
          title: 'Composition',
          details: [
            'Cirque Arlette Gruss',
            'Contemporary music published by C.P. Editions and Regia Editions (Duos, quartet, solo)',
            'Music for the Marly-Frescaty Combat Air Force recruitment film',
            'Spookla'
          ]
        },
        arrangements: {
          title: 'Arrangements',
          details: [
            'Spookla',
            'Lucas',
            'Guitariste.com "CD Concept"',
            'Equinoxe'
          ]
        },
        studio: {
          title: 'Studio',
          details: [
            'Guitar and bass, see discography page'
          ]
        },
        productions: {
          title: 'Productions',
          details: [
            'Production credits: EMI Classic, TMS Productions, Digital Juice, Kavall Records',
            'Piano/vocal reduction of Bumblefoot\'s "Uncool" album for Jaff',
            'Transcriptions for Les Nuits de Champagne',
            'Sacem transcriptions...'
          ]
        }
      },
      es: {
        composition: {
          title: 'Composición',
          details: [
            'Cirque Arlette Gruss',
            'Música contemporánea publicada por C.P. Editions y Regia Editions (Dúos, cuarteto, solo)',
            'Música para el film de reclutamiento de la Fuerza Aérea de Combate de Marly-Frescaty',
            'Spookla'
          ]
        },
        arrangements: {
          title: 'Arreglos',
          details: [
            'Spookla',
            'Lucas',
            'Guitariste.com "CD Concept"',
            'Equinoxe'
          ]
        },
        studio: {
          title: 'Estudio',
          details: [
            'Guitarra y bajo, ver página de discografía'
          ]
        },
        productions: {
          title: 'Producciones',
          details: [
            'Créditos de producción: EMI Classic, TMS Productions, Digital Juice, Kavall Records',
            'Reducción piano/voz del álbum "Uncool" de Bumblefoot para Jaff',
            'Transcripciones para Les Nuits de Champagne',
            'Transcripciones Sacem...'
          ]
        }
      }
    }[language];
  };

  const getTeachingDetails = () => {
    return {
      fr: {
        current: {
          title: 'Poste actuel',
          details: [
            'netMusicien: cours de guitare, basse, rythme, ear training et théorie musicale (depuis 2012)'
          ]
        },
        previous: {
          title: 'Postes précédents',
          details: [
            'Conservatoire de Vigneux/Seine (2021-2023): Guitare MAA, Pratiques collectives MAA',
            'École Atla (Paris, 2008-2019): Basse, oreille, atelier, rythme, guitare, M.A.O.',
            'Swing Romane Académie (2019-2020): Théorie, rythme, déchiffrage-repiquage',
            'Ateliers d\'Art (Saint-Maur, 2008-2017): Guitare',
            'ICM (Paris, 2006-2007): Guitare',
            'CALM (Metz 2001-2002): Basse, direction pédagogique',
            'ALDAM (Lorry 2001-2002): Guitare et solfège',
            'I.M.A. (Metz 1992-2000): Basse, guitare, solfège'
          ]
        }
      },
      en: {
        current: {
          title: 'Current Position',
          details: [
            'netMusicien: guitar, bass, rhythm, ear training and music theory lessons (since 2012)'
          ]
        },
        previous: {
          title: 'Previous Positions',
          details: [
            'Vigneux/Seine Conservatory (2021-2023): MAA Guitar, MAA Group Practice',
            'Atla School (Paris, 2008-2019): Bass, ear training, group workshop, rhythm, guitar, DAW',
            'Swing Romane Académie (2019-2020): Theory, rhythm, sight-reading/transcription',
            'Ateliers d\'Art (Saint-Maur, 2008-2017): Guitar',
            'ICM (Paris, 2006-2007): Guitar',
            'CALM (Metz 2001-2002): Bass, educational direction',
            'ALDAM (Lorry 2001-2002): Guitar and music theory',
            'I.M.A. (Metz 1992-2000): Bass, guitar, music theory'
          ]
        }
      },
      es: {
        current: {
          title: 'Puesto Actual',
          details: [
            'netMusicien: clases de guitarra, bajo, ritmo, entrenamiento auditivo y teoría musical (desde 2012)'
          ]
        },
        previous: {
          title: 'Puestos Anteriores',
          details: [
            'Conservatorio de Vigneux/Seine (2021-2023): Guitarra MAA, Prácticas colectivas MAA',
            'Escuela Atla (París, 2008-2019): Bajo, oído, taller, ritmo, guitarra, DAW',
            'Swing Romane Académie (2019-2020): Teoría, ritmo, lectura a primera vista/transcripción',
            'Ateliers d\'Art (Saint-Maur, 2008-2017): Guitarra',
            'ICM (París, 2006-2007): Guitarra',
            'CALM (Metz 2001-2002): Bajo, dirección pedagógica',
            'ALDAM (Lorry 2001-2002): Guitarra y solfeo',
            'I.M.A. (Metz 1992-2000): Bajo, guitarra, solfeo'
          ]
        }
      }
    }[language];
  };

  const getFormationDetails = () => {
    return {
      fr: {
        conservatory: {
          title: 'Conservatoire de Metz',
          details: [
            'Admis en Élémentaire II (solfège) en 1989',
            'DFE en solfège (1994 - cours de Michel Nutz)',
            'Médaille d\'or de solfège (1995 - cours de Catherine Speyer)',
            'Modules d\'arrangements et pratiques d\'ensemble au Département Jazz (1996)',
            '2e prix de perfectionnement en solfège (1997 - cours de Daniel Kalisky)',
            'Module d\'Histoire du Jazz (1998)'
          ]
        },
        other: {
          title: 'Autres formations',
          details: [
            'Guitare, contrebasse, atelier de musique contemporaine, MAO, écriture',
            '"Niveau Deug" en musicologie',
            'Bac A3 Musique (1993 - L. Fabert à Metz)',
            'Formation vocale: Objectif Scène (N. Bentolila & E. Morales, jan/mar 2006)'
          ]
        }
      },
      en: {
        conservatory: {
          title: 'Metz Conservatory',
          details: [
            'Admitted to Elementary II (music theory) in 1989',
            'DFE in music theory (1994 - Michel Nutz\'s class)',
            'Gold medal in music theory (1995 - Catherine Speyer\'s class)',
            'Arrangement modules and ensemble practice in Jazz Department (1996)',
            '2nd advanced prize in music theory (1997 - Daniel Kalisky\'s class)',
            'Jazz History Module (1998)'
          ]
        },
        other: {
          title: 'Other Training',
          details: [
            'Guitar, double bass, contemporary music workshop, DAW, composition',
            '"DEUG level" in musicology',
            'Baccalauréat A3 Music (1993 - L. Fabert in Metz)',
            'Vocal training: Objectif Scène (N. Bentolila & E. Morales, Jan/Mar 2006)'
          ]
        }
      },
      es: {
        conservatory: {
          title: 'Conservatorio de Metz',
          details: [
            'Admitido en Elemental II (solfeo) en 1989',
            'DFE en solfeo (1994 - clase de Michel Nutz)',
            'Medalla de oro en solfeo (1995 - clase de Catherine Speyer)',
            'Módulos de arreglos y práctica de conjunto en el Departamento de Jazz (1996)',
            '2º premio avanzado en solfeo (1997 - clase de Daniel Kalisky)',
            'Módulo de Historia del Jazz (1998)'
          ]
        },
        other: {
          title: 'Otras Formaciones',
          details: [
            'Guitarra, contrabajo, taller de música contemporánea, DAW, composición',
            '"Nivel DEUG" en musicología',
            'Bachillerato A3 Música (1993 - L. Fabert en Metz)',
            'Formación vocal: Objectif Scène (N. Bentolila & E. Morales, ene/mar 2006)'
          ]
        }
      }
    }[language];
  };

  const toggleSection = (section) => {
    setOpenSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  // Navigation helper
  const navigateTo = (path) => {
    navigate(getRoutePath(`${lang}${path}`));
  };

  // Main career data structure
  const careerData = {
    scene: {
      title: t('career.scene.title'),
      experiences: [
        {
          title: t('career.scene.concerts'),
          details: getFormattedSceneDetails().concerts
        },
        {
          title: t('career.scene.tv'),
          details: getFormattedSceneDetails().tv
        },
        {
          title: t('career.scene.collaborations'),
          details: getFormattedSceneDetails().collaborations
        }
      ]
    },
    experience: {
      title: t('career.experience.title'),
      experiences: Object.entries(getExperienceDetails()).map(([_, data]) => ({
        title: data.title,
        details: data.details
      }))
    },
    teaching: {
      title: t('career.teaching.title'),
      experiences: Object.entries(getTeachingDetails()).map(([_, data]) => ({
        title: data.title,
        details: data.details
      }))
    },
    formation: {
      title: t('career.formation.title'),
      experiences: Object.entries(getFormationDetails()).map(([_, data]) => ({
        title: data.title,
        details: data.details
      }))
    }
  };

  return (
    <div className="max-w-4xl mx-auto space-y-6">
      {Object.entries(careerData).map(([section, data]) => (
        <div key={section} className="bg-dark-surface rounded-lg shadow-lg overflow-hidden">
          <div className="p-6">
            <SectionHeader
              title={data.title}
              isOpen={openSections[section]}
              onClick={() => toggleSection(section)}
            />
          
            {openSections[section] && (
              <div className="mt-4 space-y-6">
                {data.experiences.map((exp, index) => (
                  <ExperienceItem
                    key={index}
                    title={exp.title}
                    details={exp.details}
                  />
                ))}
              </div>
            )}
          </div>  
        </div>
      ))}
    </div>
  );
};

export default Career;