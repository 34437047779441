import { AVAILABLE_LANGUAGES, translations } from './translations';

export const getInitialLanguage = () => {
  // Vérifie d'abord l'URL actuelle
  const pathname = window.location.pathname;
  const urlLang = pathname.split('/')[1];  // Prend le premier segment après '/'
  
  if (AVAILABLE_LANGUAGES.includes(urlLang)) {
    return urlLang;
  }
  
  // Si pas de langue dans l'URL, utilise la langue du navigateur
  const browserLangs = navigator.languages || [navigator.language];
  
  for (const lang of browserLangs) {
    const primaryLang = lang.split('-')[0].toLowerCase();
    if (AVAILABLE_LANGUAGES.includes(primaryLang)) {
      return primaryLang;
    }
  }
  
  return 'en'; // Langue par défaut
};

export const useTranslation = (language) => {
  const t = (key) => {
    const keys = key.split('.');
    let value = translations[language];
    
    for (const k of keys) {
      value = value?.[k];
    }
    
    if (!value) {
      console.warn(`Translation missing for key: ${key} in language: ${language}`);
    }
    
    return value || key;
  };
  
  return { t };
};