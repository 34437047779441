import { useLanguage } from '../contexts/LanguageContext';
import { useTranslation } from '../utils/language';
import { LAYOUT_CONSTANTS } from '../utils/constants';
import SEOHead from '../components/SEOHead';

const Links = () => {
  const { language } = useLanguage();
  const { t } = useTranslation(language);

  return (
    <>
    <SEOHead
      title={t('seo.links.title')}
      description={t('seo.links.description')}
    />
      <div className="w-full">
        <iframe
          src="https://www.mindomo.com/mindmap/mes-liens-f7ef52b98b0a40f68ff75f9d9aef9199"
          title="Mes liens"
          className="w-full border-none"
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
          allow="fullscreen"
          referrerPolicy="origin"
          style={{
            height: `calc(100vh - ${LAYOUT_CONSTANTS.HEADER_HEIGHT}px)`,
            minHeight: '600px'
          }}
        />
      </div>
    </>
  );
};

export default Links;
