import React, { useState } from 'react';
import { getRoutePath } from '../utils/paths';
import { NavLink, useParams } from 'react-router-dom';
import { useLanguage } from '../contexts/LanguageContext';
import { useTranslation } from '../utils/language';
import { LAYOUT_CONSTANTS, NAV_LINKS } from '../utils/constants';
import LanguageSwitcher from './LanguageSwitcher';

const Navigation = () => {
 const { language } = useLanguage();
 const { t } = useTranslation(language);
 const { lang } = useParams();
 const [menuOpen, setMenuOpen] = useState(false);
 
 const toggleMenu = () => {
   setMenuOpen(!menuOpen);
 };
 
 return (
    <nav className="bg-black/50 shadow-md backdrop-blur-sm">
     <div className={`max-w-[${LAYOUT_CONSTANTS.MAX_CONTENT_WIDTH}px] mx-auto`}>
       <div className="flex justify-between items-center py-4 px-4">
         {/* Mobile menu button */}
         <button 
           className="lg:hidden flex flex-col justify-center items-center" 
           onClick={toggleMenu}
           aria-label="Toggle menu"
         >
           <span className={`block w-6 h-0.5 bg-amber-100 transition-transform duration-300 ${menuOpen ? 'rotate-45 translate-y-1.5' : ''}`}></span>
           <span className={`block w-6 h-0.5 bg-amber-100 my-1 transition-opacity duration-300 ${menuOpen ? 'opacity-0' : ''}`}></span>
           <span className={`block w-6 h-0.5 bg-amber-100 transition-transform duration-300 ${menuOpen ? '-rotate-45 -translate-y-1.5' : ''}`}></span>
         </button>
         
         {/* Desktop navigation */}
         <ul className="hidden lg:flex space-x-6">
           {NAV_LINKS.map((route) => (
             <li key={route}>
              <NavLink 
                to={route === 'home' ? getRoutePath(`${lang}`) : getRoutePath(`${lang}/${route}`)}
                className={({ isActive }) => `transition-colors ${
                  isActive 
                    ? 'text-amber-500' 
                    : 'text-amber-100 hover:text-amber-400'
                }`}
              >
                {t(`navigation.${route}`)}
              </NavLink>
             </li>
           ))}
         </ul>
         
         <LanguageSwitcher />
       </div>
       
       {/* Mobile navigation menu */}
       <div className={`lg:hidden ${menuOpen ? 'block' : 'hidden'}`}>
         <ul className="flex flex-col space-y-4 pb-4 px-4">
           {NAV_LINKS.map((route) => (
             <li key={route}>
              <NavLink 
                to={route === 'home' ? getRoutePath(`${lang}`) : getRoutePath(`${lang}/${route}`)}
                className={({ isActive }) => `transition-colors block ${
                  isActive 
                    ? 'text-amber-500' 
                    : 'text-amber-100 hover:text-amber-400'
                }`}
                onClick={() => setMenuOpen(false)}
              >
                {t(`navigation.${route}`)}
              </NavLink>
             </li>
           ))}
         </ul>
       </div>
     </div>
   </nav>
 );
};

export default Navigation;