import React from 'react';

const YouTubeEmbed = React.memo(({ videoId, title }) => (
  <iframe
    className="w-full aspect-video rounded-lg"
    src={`https://www.youtube-nocookie.com/embed/${videoId}`}
    title={title}
    frameBorder="0"
    loading="lazy"
    allow="picture-in-picture"
    allowFullScreen
  />
));

export default YouTubeEmbed;