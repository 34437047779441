{/* Composant temporairement désactivé en attendant la configuration Mailchimp */}
{/* 
import React, { useState } from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import { useTranslation } from '../utils/language';

const Newsletter = () => {
  const { language } = useLanguage();
  const { t } = useTranslation(language);
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState({
    type: '',
    message: ''
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus({ type: 'info', message: t('newsletter.sending') });

    try {
      const response = await fetch('/api/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          language,
          preferredLanguage: navigator.language || 'en',
          browserLanguages: navigator.languages || [navigator.language],
        })
      });

      const data = await response.json();

      if (response.ok) {
        setStatus({
          type: 'success',
          message: t('newsletter.success')
        });
        setEmail('');
      } else {
        throw new Error(data.message || 'Subscription failed');
      }
    } catch (error) {
      setStatus({
        type: 'error',
        message: t('newsletter.error')
      });
    }
  };

  return (
    <div className="bg-gray-50 py-8">
      <div className="container mx-auto px-4">
        <form onSubmit={handleSubmit} className="max-w-md mx-auto">
          <h3 className="text-xl font-bold mb-4">{t('newsletter.title')}</h3>
          
          <div className="space-y-4">
            <div className="flex gap-2">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={t('newsletter.placeholder')}
                className="flex-1 px-4 py-2 border rounded focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
                required
              />
              <button
                type="submit"
                className="bg-orange-500 text-white px-6 py-2 rounded hover:bg-orange-600 transition-colors"
              >
                {t('newsletter.submit')}
              </button>
            </div>

            {status.message && (
              <div 
                className={`p-4 rounded-lg ${
                  status.type === 'success' ? 'bg-green-50 text-green-800' :
                  status.type === 'error' ? 'bg-red-50 text-red-800' :
                  'bg-blue-50 text-blue-800'
                }`}
              >
                {status.message}
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};
*/}

const Newsletter = () => null;

export default Newsletter;