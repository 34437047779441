import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

/**
 * Un composant captcha mathématique simple qui génère et vérifie des opérations mathématiques basiques.
 * 
 * @component
 * @param {Object} props
 * @param {function} props.onValidate - Callback appelé avec le statut de validation (true/false)
 * @param {string} [props.className] - Classes CSS additionnelles pour le conteneur
 * @param {Object} [props.translations] - Traductions personnalisées
 */
const MathCaptcha = ({ 
  onValidate, 
  className = '',
  translations = {
    new: '↻ Nouveau',
    placeholder: '?'
  }
}) => {
  const [firstNumber, setFirstNumber] = useState(0);
  const [secondNumber, setSecondNumber] = useState(0);
  const [operator, setOperator] = useState('+');
  const [userAnswer, setUserAnswer] = useState('');
  const [isValid, setIsValid] = useState(false);

  /**
   * Génère un nouveau défi mathématique
   */
  const generateChallenge = () => {
    // Nombres entre 1 et 10 pour des calculs simples
    const num1 = Math.floor(Math.random() * 10) + 1;
    const num2 = Math.floor(Math.random() * 10) + 1;
    const operators = ['+', '-', 'x'];
    const randomOperator = operators[Math.floor(Math.random() * operators.length)];
    
    setFirstNumber(num1);
    setSecondNumber(num2);
    setOperator(randomOperator);
    setUserAnswer('');
    setIsValid(false);
    onValidate(false);
  };

  /**
   * Calcule la réponse correcte pour l'opération en cours
   * @returns {number} Le résultat de l'opération
   */
  const calculateCorrectAnswer = () => {
    switch (operator) {
      case '+':
        return firstNumber + secondNumber;
      case '-':
        return firstNumber - secondNumber;
      case 'x':
        return firstNumber * secondNumber;
      default:
        return 0;
    }
  };

  /**
   * Vérifie la réponse fournie par l'utilisateur
   * @param {string} value - La réponse de l'utilisateur
   */
  const checkAnswer = (value) => {
    const userValue = parseInt(value, 10);
    if (isNaN(userValue)) {
      setIsValid(false);
      onValidate(false);
      return;
    }

    const correct = calculateCorrectAnswer();
    const isCorrect = userValue === correct;
    setIsValid(isCorrect);
    onValidate(isCorrect);
  };

  // Génère un nouveau défi au montage du composant
  useEffect(() => {
    generateChallenge();
  }, []);

  return (
    <div className={`bg-dark-surface/50 p-4 rounded-lg ${className}`}>
      <div className="flex items-center justify-between mb-3">
        <span className="text-lg font-semibold text-gray-100">
          {firstNumber} {operator} {secondNumber} = ?
        </span>
        <button
          type="button"
          onClick={generateChallenge}
          className="text-sm text-amber-500 hover:text-amber-400 transition-colors"
          aria-label="Générer un nouveau calcul"
        >
          {translations.new}
        </button>
      </div>
      
      <div className="flex gap-2 items-center">
        <input
          type="number"
          value={userAnswer}
          onChange={(e) => {
            setUserAnswer(e.target.value);
            checkAnswer(e.target.value);
          }}
          className="w-30 px-3 py-2 border rounded-lg focus:ring-2 focus:ring-amber-500 bg-dark-surface text-gray-100"
          placeholder={translations.placeholder}
          aria-label="Votre réponse"
          required
        />
        
        {userAnswer && (
          <div 
            className={`flex items-center ${isValid ? 'text-green-500' : 'text-red-500'}`}
            role="status"
            aria-label={isValid ? 'Réponse correcte' : 'Réponse incorrecte'}
          >
            {isValid ? '✓' : '✗'}
          </div>
        )}
      </div>
    </div>
  );
};

MathCaptcha.propTypes = {
  onValidate: PropTypes.func.isRequired,
  className: PropTypes.string,
  translations: PropTypes.shape({
    new: PropTypes.string,
    placeholder: PropTypes.string
  })
};

export default MathCaptcha;
