import React from 'react';
import { getAssetPath, getRoutePath } from '../utils/paths';
import { useLanguage } from '../contexts/LanguageContext';
import { useTranslation } from '../utils/language';
import { useNavigate, useParams } from 'react-router-dom';
import SEOHead from '../components/SEOHead';
import YouTubeEmbed from '../components/YouTubeEmbed';

const Home = () => {
  const { language } = useLanguage();
  const { t } = useTranslation(language);
  const navigate = useNavigate();
  const { lang } = useParams();

  return (
    <>
    <SEOHead
      title={t('seo.home.title')}
      description={t('seo.home.description')}
    />
      <div className="max-w-4xl mx-auto text-gray-100">
        <div className="grid md:grid-cols-2 gap-8 mb-12">
          {/* Left Column */}
          <div>
            <h1 className="text-2xl text-amber-500 mb-6">
              Régis Lamora
            </h1>
            <h2 className="text-xl mb-4">
              {t('home.tagline')}
            </h2>

            <div className="bg-orange-50/10 rounded-lg p-6">
              <h3 className="text-lg font-bold text-amber-500 mb-4">{t('home.activities')}</h3>
              <ul className="space-y-3">
                <li className="flex items-center">
                  <div className="w-2 h-2 bg-amber-500 rounded-full mr-3"></div>
                  {t('home.activity1')}
                </li>
                <li className="flex items-center">
                  <div className="w-2 h-2 bg-amber-500 rounded-full mr-3"></div>
                  {t('home.activity2')}
                </li>
                <li className="flex items-center">
                  <div className="w-2 h-2 bg-amber-500 rounded-full mr-3"></div>
                  {t('home.activity3')}
                </li>
              </ul>
            </div>
          </div>

          {/* Right Column - Online Lessons */}
          <div className="bg-orange-50/10 rounded-lg p-6">
            <img 
              src={getAssetPath('/images/teaching.png')}
              alt="Cours de musique en ligne"
              className="w-full h-auto rounded-lg shadow-lg mb-6"
            />
            <h3 className="text-xl font-bold text-amber-500 mb-4">{t('home.onlineLessons')}</h3>
            <p className="mb-6">
              {t('home.lessonsDescription')}
            </p>
            <button 
              onClick={() => navigate(getRoutePath(`${lang}/courses`))}
              className="bg-amber-500 text-black px-6 py-2 rounded hover:bg-amber-600 transition-colors"
            >
              {t('home.learnMore')}
            </button>
          </div>
        </div> 

        {/* Videos Section */}
        <div className="grid md:grid-cols-2 gap-8">
          <div>
            <YouTubeEmbed 
              videoId="uHMsdIAQ--8"
              title="Donna steps"
            />
          </div>
          <div>
            <YouTubeEmbed 
              videoId="ZPdzzeIWHiQ"
              title="Acordeonita"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;