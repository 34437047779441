import React from 'react';
import SocialLinks from './SocialLinks';

const Footer = () => {
  return (
    <footer className="bg-black-50 py-6 backdrop-blur-sm">
      <div className="max-w-[1024px] mx-auto px-4">
        <div className="flex justify-between items-center">
          <SocialLinks />
          <div className="text-sm text-gray-300">
            © {new Date().getFullYear()} Régis Lamora
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;