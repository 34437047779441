import { useLanguage } from '../contexts/LanguageContext';
import { useTranslation } from '../utils/language';
import { LAYOUT_CONSTANTS } from '../utils/constants';
import SEOHead from '../components/SEOHead';

const Courses = () => {
  const { language } = useLanguage();
  const { t } = useTranslation(language);

  return (
    <>
    <SEOHead
      title={t('seo.courses.title')}
      description={t('seo.courses.description')}
    />
      <div className="w-full">
        <iframe
          src="https://netmusicien.fr"
          title="Cours de musique en ligne"
          className="w-full border-none"
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
          allow="fullscreen"
          referrerPolicy="origin"
          style={{
            height: `calc(100vh - ${LAYOUT_CONSTANTS.HEADER_HEIGHT}px)`,
            minHeight: '600px'
          }}
        />
      </div>
    </>
  );
};

export default Courses;