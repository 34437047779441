import React from 'react';
import { getRoutePath } from '../utils/paths';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useLanguage } from '../contexts/LanguageContext';
import { AVAILABLE_LANGUAGES } from '../utils/translations';

const LanguageSwitcher = () => {
  const { language, setLanguage } = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();
  const { lang } = useParams();
  
  const handleLanguageChange = (newLang) => {
    if (newLang === language) return;
    
    // Met à jour le contexte de langue
    setLanguage(newLang);
    
    // Construit la nouvelle URL
    const currentPath = location.pathname;
    // Enlève la base URL si elle existe
    const baseUrl = import.meta.env.VITE_BASE_URL || '';
    let pathWithoutBase = currentPath;
    if (baseUrl && currentPath.startsWith(baseUrl)) {
      pathWithoutBase = currentPath.substring(baseUrl.length);
    }
    // Remplace la langue
    const pathWithNewLang = pathWithoutBase.replace(`/${lang}`, `/${newLang}`);
    // Reconstruit l'URL complète
    const newPath = getRoutePath(pathWithNewLang);
    
    // Navigue vers la nouvelle URL
    navigate(newPath);
  };

  return (
    <div className="flex space-x-2">
      {AVAILABLE_LANGUAGES.map((lang) => (
        <button
          key={lang}
          onClick={() => handleLanguageChange(lang)}
          className={`px-2 py-1 rounded transition-all duration-200 ${
            language === lang 
              ? 'bg-orange-400 text-white shadow-sm' 
              : 'text-gray-400 hover:text-orange-500 hover:bg-orange-100'
          }`}
          aria-label={`Switch to ${lang.toUpperCase()}`}
          aria-pressed={language === lang}
        >
          {lang.toUpperCase()}
        </button>
      ))}
    </div>
  );
};

export default LanguageSwitcher;