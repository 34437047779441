import React from 'react';
import { useState } from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import { useTranslation } from '../utils/language';
import SEOHead from '../components/SEOHead';
import SecureAudioPlayer from '../pages/SecureAudioPlayer';
import YouTubeEmbed from '../components/YouTubeEmbed';

// Album Component
const Album = ({ 
  title, 
  year, 
  artist, 
  credits = [], 
  videoUrl, 
  imageUrl,
  type,
  tracks = [],
  personnel = []
 }) => {
  const { t } = useLanguage();
  const [imageLoaded, setImageLoaded] = useState(false);
 
  const formatInstruments = (instruments) => {
    if (Array.isArray(instruments)) {
      return instruments
        .map(instrument => t(`instruments.${instrument}`))
        .join(`, `);
    }
    return t(`instruments.${instruments}`);
  };
 
  const formatCredit = (credit) => {
    try {
      const { type, people } = credit;
      const peopleArray = Array.isArray(people) ? people : [people];
      const formattedTypes = Array.isArray(type) 
        ? type.map(typ => t(`credits.${typ}`)).join(` ${t('credits.and')} `)
        : t(`credits.${type}`);
      const formattedPeople = peopleArray.join(` ${t('credits.and')} `);
      return { types: formattedTypes, people: formattedPeople };
    } catch (error) {
      console.error('Error formatting credit:', error);
      return null;
    } 
  };
 
  return (
    <div className="bg-dark-surface rounded-lg shadow-lg overflow-hidden mb-8 last:mb-0">
      <div className="p-6">
        <div className="flex flex-col md:flex-row gap-6">
          {/* Media Section */}
          <div className="w-full lg:w-1/2 flex items-center justify-center">
            {videoUrl ? (
              <YouTubeEmbed
                videoId={videoUrl.split('/').pop()}
                title={`${title} - ${artist}`}
              />
            ) : type === 'audio' ? (
              <div className="flex flex-col items-center w-full">
                <SecureAudioPlayer tracks={tracks} />
              </div>
            ) : imageUrl && (
              <div className="relative w-4/5 max-h-[450px] mx-auto">
                {/* Placeholder pendant le chargement */}
                <div 
                  className={`bg-dark-surface/50 absolute inset-0 rounded-lg transition-opacity duration-300 ${
                    imageLoaded ? 'opacity-0' : 'opacity-100 animate-pulse'
                  }`}
                />
                <img 
                  src={imageUrl} 
                  alt={`${title} - ${artist}`} 
                  loading="lazy"
                  width="400"
                  height="400"
                  className={`w-full h-auto mx-auto rounded-lg mb-4 object-contain transition-opacity duration-300 ${
                    imageLoaded ? 'opacity-100' : 'opacity-0'
                  }`}
                  onLoad={() => setImageLoaded(true)}
                  onError={(e) => {
                    console.error(`Error loading image for ${title}:`, e);
                    setImageLoaded(true); // Pour enlever le placeholder en cas d'erreur
                  }}
                />
              </div>
            )}
          </div>
 
          {/* Info Section - reste inchangé */}
          <div className="flex-1">
            <h2 className="text-2xl font-bold text-amber-200 mb-2">
              {artist}
            </h2>
            <p className="text-xl text-amber-400 mb-4">
              "{title}" ({year})
            </p>
 
            {/* Credits */}
            {credits.length > 0 && (
              <div className="mb-4">
                <ul className="space-y-1">
                  {credits.map((credit, index) => {
                    const formatted = formatCredit(credit);
                    if (!formatted) return null;
                    return (
                      <li key={index} className="text-gray-100">
                        <span className="text-amber-400">
                          {formatted.types}{': '}
                        </span>
                        {formatted.people}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
 
            {/* Personnel */}
            {personnel.length > 0 && (
              <div className="mb-4">
                <h3 className="text-lg font-semibold text-amber-200 mb-2">
                  {t('discography.musicians')}:
                </h3>
                <ul className="space-y-1">
                  {personnel.map((person, index) => (
                    <li key={index} className="text-gray-100">
                      {person.instrument && (
                        <>
                          <span className="text-amber-400">
                            {formatInstruments(person.instrument)}{': '}
                          </span>
                          {person.name}
                        </>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const Discography = () => {
  const { language } = useLanguage();
  const { t } = useTranslation(language);
  
  // Définir les données après avoir accès à t()
  const discographyData = [
    { /* 2024 DORCAS - MERCURE */ 
      artist: 'Dorcas',
      title: 'Mercure',
      year: '2024',
      videoUrl: 'QbpF5vXRAR8',
      credits: [
        { type: 'lyrics', people: 'Lilian Minas, Dorcas Coppin & Gabriel Cheurfa'},
        { type: 'music', people: 'Gabriel Cheurfa'},
        { type: 'production', people: 'Gabriel Cheurfa & Pierre Duval'},
        { type: 'mixing', people: 'Julien Sabourin & Gabriel Cheurfa'},
        { type: 'mastering', people: 'Mike Marsh'}
      ],
      personnel: [
        { instrument: ['synth', 'drums', 'perc', 'piano'], name: 'Gabriel Cheurfa' },
        { instrument: 'guitar', name: 'Pierre Duval' },
        { instrument: 'bass', name: 'Régis Lamora' }
      ]
    },
    { /* 2023 DORCAS - TU ME MANQUES */ 
      artist: 'Dorcas',
      title: 'Tu me manques',
      year: '2023',
      credits: [
        { type: 'lyrics', people: 'Martin Lefèbvre & Camille Lemay'},
        { type: 'music', people: 'Martin Lefèbvre, Gabriel Cheurfa & Pierre Duval'},
        { type: ['production', 'arrangement'], people: 'Gabriel Cheurfa, Pierre Duval & Julien Sabourin'},
        { type: 'mixing', people: 'Julien Sabourin & Gabriel Cheurfa'},
        { type: 'mastering', people: 'Mike Marsh'}
      ],
      personnel: [
        { instrument: ['synth', 'drums', 'perc', 'piano'], name: 'Gabriel Cheurfa' },
        { instrument: 'guitar', name: 'Pierre Duval' },
        { instrument: 'bass', name: 'Régis Lamora' }
      ],
      videoUrl: 'https://www.youtube.com/embed/HT1DY8IqDIU'
    },
    { /* 2022 DORCAS - TIGER */ 
      artist: 'Dorcas',
      title: 'Tiger',
      year: '2022',
      credits: [
        { type: 'lyrics', people: 'Martin Lefèbvre & Camille Lemay'},
        { type: 'music', people: 'Martin Lefèbvre, Gabriel Cheurfa & Pierre Duval'},
        { type: ['production', 'arrangement'], people: 'Gabriel Cheurfa, Pierre Duval & Julien Sabourin'},
        { type: 'mixing', people: 'Julien Sabourin & Gabriel Cheurfa'},
        { type: 'mastering', people: 'Mike Marsh'}
      ],
      personnel: [
        { instrument: ['synth', 'drums', 'perc', 'piano'], name: 'Gabriel Cheurfa' },
        { instrument: 'guitar', name: 'Pierre Duval' },
        { instrument: 'bass', name: 'Régis Lamora' }
      ],
      videoUrl: 'https://www.youtube.com/embed/W50e819TIqU'
    },
    { /* 2022 FABIEN INCARDONA - EP - JE ME DEMANDE */ 
      artist: 'Fabien Incardona',
      title: 'Je me demande',
      year: 'EP, 2022',
      type: 'audio',
      tracks: [ 
        {
          title: 'Je me demande',
          url: 'https://dtlck96hf0n6q.cloudfront.net/audio/2022-inca-je-me-demande.mp3',
          imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/2022-inca-je-me-demande.jpg'
        }
      ],
      credits: [
        { type: 'lyrics', people: 'Fabien Incardona & Mike Dean'},
        { type: ['music', 'arrangement'], people: 'Fabien Incardona, Igor Bolender & Pierre Duval'},
        { type: 'mixing', people: 'Pierre Duval'}
      ],
      personnel: [
        { instrument: 'synth', name: 'Igor Bolender'},
        { instrument: 'guitar', name: 'Pierre Duval'},
        { instrument: 'drums', name: 'Adrien Garbar'},
        { instrument: 'bass', name: 'Régis Lamora'}
      ],
      imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/2022-inca-je-me-demande.jpg'
    },
    { /* 2021 FABIEN INCARDONA - JE ME DEMANDE */ 
      artist: 'Fabien Incardona',
      title: 'Je me demande',
      year: 'Single, 2021',
      credits: [
        { type: 'direction', people: 'Thierry Vergnes'},
        { type: ['lyrics', 'music'], people: 'Fabien Incardona'},
        { type: ['AD', 'arrangement'], people: 'Igor Bolender & Pierre Duval'},
        { type: 'mixing', people: 'Pierre Duval'}
      ],
      personnel: [
        { instrument: 'synth', name: 'Igor Bolender'},
        { instrument: 'guitar', name: 'Pierre Duval'},
        { instrument: 'drums', name: 'Adrien Garbar'},
        { instrument: 'bass', name: 'Régis Lamora'}
      ],
      videoUrl: 'https://www.youtube.com/embed/NmI2wZ20-VI'
    },
    { /* 2019 SIDDHARTHA - PEUT-ÊTRE */ 
      artist: 'Siddhartha, l\'Opéra Rock',
      title: 'Peut-être',
      year: 'Single, 2019',
      credits: [
        { type: 'direction', people: 'Rémi Cluzeau'},
        { type: ['lyrics', 'music'], people: 'Estelle Samson & David Clément-Bayard'},
        { type: 'arrangement', people: 'Igor Bolender & David Clément-Bayard'},
        { type: 'AD', people: 'Antoine Markus, Fred Juarez & Philippe Fretault'},
        { type: 'mixing', people: 'Pierre Duval'}
      ],
      personnel: [
        { instrument: 'vocals', name: 'Mélissa Forton & Julien Arcuri'},
        { instrument: 'synth', name: 'Igor Bolender'},
        { instrument: 'guitar', name: 'Pierre Duval & Viroun Kattygnarath'},
        { instrument: 'drums', name: 'Adrien Garbar'},
        { instrument: 'bass', name: 'Régis Lamora'}
      ],
      videoUrl: 'https://www.youtube.com/embed/d3PEGvRcbDc'
    },
    { /* 2019 SIDDHARTHA - SERAS-TU FIER ? */ 
      artist: 'Siddhartha, l\'Opéra Rock',
      title: 'Seras-tu fier ?',
      year: 'Single, 2019',
      credits: [
        { type: 'direction', people: 'Sabine Petit & Frank Berjot'},
        { type: ['lyrics', 'music'], people: 'David Clément-Bayard'},
        { type: 'arrangement', people: 'Igor Bolender & David Clément-Bayard'},
        { type: 'AD', people: 'Antoine Markus, Fred Juarez & Philippe Fretault'}
      ],
      personnel: [
        { instrument: 'vocals', name: 'Inca'},
        { instrument: 'synth', name: 'Igor Bolender'},
        { instrument: 'guitar', name: 'Pierre Duval & Viroun Kattygnarath'},
        { instrument: 'drums', name: 'Adrien Garbar'},
        { instrument: 'bass', name: 'Régis Lamora'}
      ],
      videoUrl: 'https://www.youtube.com/embed/Yf14Nl6-Ml0'
    },
    { /* 2019 SIDDHARTHA - LA VIE M'ATTEND */ 
      artist: 'Siddhartha, l\'Opéra Rock',
      title: 'La vie m\'attend',
      year: 'Single, 2019',
      credits: [
        { type: 'direction', people: 'Sabine Petit & Frank Berjot'},
        { type: 'lyrics', people: 'Crystal G & David Clément-Bayard'},
        { type: 'music', people: 'David Clément-Bayard'},
        { type: 'arrangement', people: 'Igor Bolender & David Clément-Bayard'},
        { type: 'AD', people: 'Antoine Markus, Fred Juarez & Philippe Fretault'}
      ],
      personnel: [
        { instrument: 'vocals', name: 'Inca'},
        { instrument: 'synth', name: 'Igor Bolender'},
        { instrument: 'guitar', name: 'Pierre Duval & Viroun Kattygnarath'},
        { instrument: 'drums', name: 'Adrien Garbar'},
        { instrument: 'bass', name: 'Régis Lamora'}
      ],
      videoUrl: 'https://www.youtube.com/embed/fi9veDpo65Y'
    },
    { /* 2019 SIDDHARTHA - IL ETAIT UNE FOIS BOUDDHA */ 
      artist: 'Siddhartha, l\'Opéra Rock',
      title: 'Il était une fois Bouddha...',
      year: 'Album, 2019',
      credits: [
        { type: ['lyrics', 'music'], people: 'David Clément-Bayard'},
        { type: 'arrangement', people: 'Igor Bolender & David Clément-Bayard'}
      ],
      personnel: [
        { instrument: 'vocals', name: 'Inca, Julien Arcuri, David Clément-Bayard, Océane Berland, Camille Millian, Melissa Forton & Stéphanie Schlesser'},
        { instrument: 'synth', name: 'Igor Bolender'},
        { instrument: 'guitar', name: 'Pierre Duval & Viroun Kattygnarath'},
        { instrument: 'drums', name: 'Adrien Garbar'},
        { instrument: ['bass', 'fretless'], name: 'Régis Lamora'}
      ],
      imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/2022-siddhartha-album.jpg'
    },
    { /* 2016 EDITH LAMORA - ET SI */ 
      artist: 'Edith Lamora',
      title: 'Et si',
      year: 'Single, 2016',
      credits: [
        { type: 'lyrics', people: 'Edith Lamora'},
        { type: 'music', people: 'Edith Lamora & Régis Lamora'},
        { type: 'direction', people: 'Régis Lamora'},
        { type: ['arrangement', 'mixing', 'mastering'], people: 'Régis Lamora'}
      ],
      personnel: [
        { instrument: 'vocals', name: 'Edith Lamora'},
        { instrument: 'prog', name: 'Régis Lamora'}
      ],
      videoUrl: 'https://www.youtube.com/embed/VH-CpxmQUlw'
      /*
        "Et si" est disponible sur 
        - iTunes: https://itunes.apple.com/fr/album/et-si-single/id1107413326?i=1107413349&l=fr
        - Amazon: http://www.amazon.fr/gp/product/B01EQIQVM2/
      */
    },
    { /* 2015 EDITH LAMORA - AU BOUT DU MONDE */ 
      artist: 'Edith Lamora',
      title: 'Au bout du monde',
      year: 'Single, 2015',
      credits: [
        { type: 'lyrics', people: 'Edith Lamora'},
        { type: 'music', people: 'Edith Lamora & Régis Lamora'},
        { type: ['arrangement', 'mixing'], people: 'Régis Lamora'},
        { type: 'mastering', people: 'Jean-Charles Panizza (Climax Mastering)'},
        { type: 'direction', people: 'Régis Lamora & André Kadi (Motion design, FX), Hoang Pham (illustration)'},
      ],
      personnel: [
        { instrument: 'vocals', name: 'Edith Lamora'},
        { instrument: ['prog', 'guitar'], name: 'Régis Lamora'}
      ],
      videoUrl: 'https://www.youtube.com/embed/9aksIic93-s'
      /* 
        "Au bout du monde" est disponible sur 
        - iTunes: https://itun.es/fr/eC-I8
        - Amazon: http://www.amazon.fr/Au-Bout-Du-Monde/dp/B010XVOUO2/ref=pd_ecc_rvi_1
      */  
    },
    { /* 2015 TOUBAB OR NOT TOUBAB */ 
      artist: 'Toubab or not Toubab',
      title: 'Dabondy III',
      year: '2015',
      type: 'audio',
      tracks: [
        {
          title: 'Mansani Cisse',
          url: 'https://dtlck96hf0n6q.cloudfront.net/audio/2015-tont-mansani-cisse.mp3',
          imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/2015-tont.jpg'
        },
        {
          title: 'Yamama',
          url: 'https://dtlck96hf0n6q.cloudfront.net/audio/2015-tont-yamama.mp3',
          imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/2015-tont.jpg'
        }
      ],
      credits: [
        { type: ['music', 'arrangement'], people: 'Guillaume Vizzutti'},
        { type: ['mixing', 'mastering'], people: 'Vincent Vizzutti'},
        { type: 'AD', people: 'Guillaume Vizzutti'}
      ],
      personnel: [
        { instrument: 'balafon', name: 'Guillaume Vizzutti'},
        { instrument: 'drums', name: 'Guihlerme Hue'},
        { instrument: ['perc', 'congas', 'djembe', 'cajon'], name: 'Frederic Jammet'},
        { instrument: 'bass', name: 'Massimo Murgia & Jean Sutter'},
        { instrument: 'guitar', name: 'Samuel Smith & Régis Lamora'}
      ],
      imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/2015-tont.jpg'
    },
/*

*/
    { /* 2014 MOTUS */ 
      artist: 'France 2',
      title: 'Motus',
      year: '2014',
      credits: [
        { type: 'arrangement', people: 'Eric Melon'},
      ],
      personnel: [
        { instrument: ['synth', 'prog'], name: 'Igor Bolender'},
        { instrument: 'vocals', name: 'Elisabeth Bayle'},
        { instrument: 'guitar', name: 'Régis Lamora'}
      ],
      imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/2014-Motus.jpg'
    },
    { /* 2012 CECILE DESTE - THE AWAKENING */ 
      artist: 'Cécile Deste',
      title: 'The awakening',
      year: '2012',
      credits: [
        { type: 'AD', people: 'Vincent Cordel'},
      ],
      personnel: [
        { instrument: 'vocals', name: 'Cécile Deste'},
        { instrument: 'piano', name: 'Vincent Cordel'},
        { instrument: 'synth', name: 'Clément Cornuau'},
        { instrument: 'guitar', name: 'Benoît Medrikowski'},
        { instrument: ['piano', 'perc'], name: 'Franck Steckar'},
        { instrument: 'drums', name: 'Jonathan Thillot'},
        { instrument: 'bass', name: 'Régis Lamora'},
        { instrument: 'breath', name: 'Lucas Fanchon'}
      ],
      imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/2012-cecile-deste.jpg'
    },
    { /* 2011 NIKOL - DEMO TRACKS */ 
      artist: 'Nikol',
      title: 'Demo',
      year: '2011',
      credits: [
        { type: ['lyrics', 'music'], people: 'Nikol'},
      ],
      personnel: [
        { instrument: 'prog', name: 'Loïc Le Dévéhat'},
        { instrument: 'guitar', name: 'Régis Lamora'}
      ],
      imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/2011-nikol.jpg',
    },
    { /* 2011 KEVIN SOEL - EP */ 
      artist: 'Kévin Soel',
      title: 'EP',
      year: '2011',
      credits: [
        { type: ['lyrics', 'music'], people: 'Kévin Soel'},
      ],
      personnel: [
        { instrument: ['vocals', 'backvoc', 'piano', 'guitar'], name: 'Kévin Soel'},        
        { instrument: 'prog', name: 'Loïc Le Dévéhat'},
        { instrument: ['bass', 'guitarel', 'guitarac'], name: 'Régis Lamora'}
      ],
      imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/2011-kevin.jpg',
    },
    { /* 2010 LUCAS - AUTOMNE HOME */ 
      artist: 'Lucas Fanchon',
      title: 'Automne home',
      year: '2010',
      credits: [
        { type: ['lyrics', 'music'], people: 'Lucas Fanchon'},
        { type: 'arrangement', people: 'Jean-Valéry Bartholmé'},
        { type: ['AD', 'mixing'], people: 'Etienne Chagnon'},     
      ],
      personnel: [
        { instrument: ['vocals', 'guitar'], name: 'Lucas Fanchon'},
        { instrument: 'vocals', name: 'Amélie Laroque'},
        { instrument: 'guitar', name: 'Jean-Valéry Bartholmé'},
        { instrument: 'drums', name: 'Patrice "Gonzo" Hue'},
        { instrument: 'bass', name: 'Régis Lamora'}
      ],
      imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/2010-lucas-automne-home.jpg',
    },
    { /* 2010 BARTOLI - TF1 */ 
      artist: 'TF1',
      title: 'La loi selon Bartoli',
      year: '2010',
      /* credits: [
      ], */
      personnel: [
        { instrument: ['drums', 'prog'], name: 'Loïc Le Dévéhat'},
        { instrument: 'vocals', name: 'Ludivine Aubourg'},
        { instrument: 'guitar', name: 'Régis Lamora'}
      ],
      imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/2010-Bartoli.jpg',
    },
    { /* 2009 DYE - ACOUSTIC PRESS */ 
      artist: 'Dyé & the Mellowz',
      title: 'Acoustic press',
      year: '2009',
      type: 'audio',
      tracks: [
        {
          title: 'Acoustic press',
          url: 'https://dtlck96hf0n6q.cloudfront.net/audio/dsampl.mp3',
          imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/2009-dye.jpg'
        }
      ],
      personnel: [
        { instrument: 'vocals', name: 'Dyé'},
        { instrument: 'backvoc', name: 'Lyviah R'},
        { instrument: 'guitar', name: 'Frédéric Etanchaud'},
        { instrument: ['flute', 'perc'], name: 'Nicolas Coyez'},
        { instrument: ['drums', 'perc'], name: 'Valentino Ramos de Figueiredo'},
        { instrument: 'bass', name: 'Régis Lamora'}
      ],
      imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/2009-dye.jpg',
    },
    { /* 2008 LUCAS - STUDIO LIVE ACOUSTIQUE */ 
      artist: 'Lucas Fanchon',
      title: 'Studio Live Acoustique',
      year: '2008',
      type: 'audio',
      tracks: [
        {
          title: 'Studio live acoustique',
          url: 'https://dtlck96hf0n6q.cloudfront.net/audio/2008-lucas-studio-live-acoustique.mp3',
          imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/2008-lucas-sla.jpg'
        }
      ],
      credits: [
        { type: ['lyrics', 'music'], people: 'Lucas Fanchon'},
      ],
      personnel: [
        { instrument: ['vocals', 'guitar'], name: 'Lucas Fanchon'},
        { instrument: 'vocals', name: 'Amélie Laroque'},
        { instrument: 'guitar', name: 'Jean-Valéry Bartholmé'},
        { instrument: ['drums', 'perc', 'glock'], name: 'Patrice "Gonzo" Hue'},
        { instrument: 'guitarlead', name: 'Régis Lamora'}
      ],
     // imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/2008-lucas-sla.jpg',
    },
    { /* 2008 CORALIE STEVENS - PRENDRE LE TEMPS */ 
      artist: 'Coralie Stevens',
      title: 'Prendre le temps',
      year: 'Single, 2008',
      credits: [
        { type: 'lyrics', people: 'Coralie Stevens'},
        { type: ['music', 'arrangement'], people: 'Loïc Le Dévéhat'},
      ],
      personnel: [
        { instrument: 'vocals', name: 'Coralie Stevens'},
        { instrument: 'backvoc', name: 'Lucas Fanchon'},
        { instrument: 'cello', name: 'Frédéric Deville'},
        { instrument: ['drums', 'synth', 'prog'], name: 'Loïc Le Dévéhat'},
        { instrument: ['guitar', 'bass', 'backvoc'], name: 'Régis Lamora'}
      ],
      imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/2008-coralie.jpg',
    },
    { /* 2007 DIGITAL JUICE - STACK TRAXX */ 
    artist: 'Digital Juice',
    title: 'Stack traxx vol. 24-25-28-29-32',
    year: '2007',
    type: 'audio',
    tracks: [
      {
        title: 'Broadcast themes (Stack Traxx 24)',
        url: 'https://dtlck96hf0n6q.cloudfront.net/audio/DJ24-broadcast-themes.mp3',
        imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/2007-dj.jpg'
      },
      {
        title: 'Sports (Stack Traxx 25)',
        url: 'https://dtlck96hf0n6q.cloudfront.net/audio/DJ25-sports.mp3',
        imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/2007-dj.jpg'
      },
      {
        title: 'Island (Stack Traxx 28)',
        url: 'https://dtlck96hf0n6q.cloudfront.net/audio/DJ28-island.mp3',
        imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/2007-dj.jpg'
      },
      {
        title: 'Dynamic intensity (Stack Traxx 29)',
        url: 'https://dtlck96hf0n6q.cloudfront.net/audio/DJ29-dynamic-intensity.mp3',
        imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/2007-dj.jpg'
      },
      {
        title: 'Latin (Stack Traxx 32)',
        url: 'https://dtlck96hf0n6q.cloudfront.net/audio/DJ32-latin.mp3',
        imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/2007-dj.jpg'
      }
    ],
    credits: [
      { type: 'music', people: 'Régis Lamora, Vincent Cordel...'},
    ],
    personnel: [
      { instrument: ['guitar', 'bass', 'prog'], name: 'Régis Lamora'}
    ],
    imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/2007-dj.jpg'
    },
    /*

    */
    { /* 2007 FENO - POUPEES DES WEST INDIES */ 
      artist: '10\'Gla',
      title: 'Poupées des West Indies',
      year: 'Album Chrysalide, 2007',
      credits: [
        { type: 'lyrics', people: 'Fenomen 10\'Gla'},
        { type: 'music', people: 'Régis Lamora'}
      ],
      personnel: [
        { instrument: ['guitar', 'bass', 'prog'], name: 'Régis Lamora'}
      ],
      imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/2007-feno.jpg'
    },
    { /* 2006 LUCAS - AMNESIE ORDINAIRE */ 
      artist: 'Lucas Fanchon',
      title: 'Amnésie ordinaire',
      year: '2006',
      credits: [
        { type: ['lyrics', 'music'], people: 'Lucas Fanchon'},
        { type: ['AD', 'production'], people: 'Laurent Lepagneau & Nicolas Varak'},
      ],
      personnel: [
        { instrument: ['vocals', 'guitar'], name: 'Lucas Fanchon'},
        { instrument: 'synth', name: 'Nicolas Varak'},
        { instrument: 'guitar', name: 'Jean-Valéry Bartholmé & Laurent Lepagneau'},
        { instrument: 'drums', name: 'Patrice "Gonzo" Hue'},
        { instrument: ['bass', 'backvoc'], name: 'Régis Lamora'}
      ],
      imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/2006-lucas-amnesie.jpg',
    },
    { /* 2006 GUITARISTE.COM - CD CONCEPT */ 
      artist: 'Guitariste.com',
      title: 'CD Concept',
      year: '2006',
      type: 'audio',
      tracks: [
        {
          title: 'Guitares',
          url: 'https://dtlck96hf0n6q.cloudfront.net/audio/CD_Concept-Guitares.mp3',
          imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/2006-guitariste.jpg'          
        },
        {
          title: 'Brainstorming',
          url: 'https://dtlck96hf0n6q.cloudfront.net/audio/CD_Concept-Brainstorming.mp3',
          imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/2006-guitariste.jpg'
        }
      ],
      credits: [
        { type: 'arrangement', people: 'Régis Lamora (Guitares & Brainstorming)'},
      ],
      personnel: [
        { instrument: 'guitar', name: 'Christophe Godin, Olivier Olmos, Matthieu Rabaté, Jean-Michel Kajdan, Nino Borgotta, Tristan Klein, Cyril Vincent, Elmobo...'},
        { instrument: ['bass', 'multigt'], name: 'Régis Lamora'}
      ],
      imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/2006-guitariste.jpg',
    },
    { /* 2003 LUCAS - THE FRENCH GUY */ 
      artist: 'Lucas Fanchon',
      title: 'The french guy',
      year: '2003',
      credits: [
        { type: ['lyrics', 'music'], people: 'Lucas Fanchon'},
        { type: 'arrangement', people: 'Régis Lamora (Les insectes, Petite sœur)'},
      ],
      personnel: [
        { instrument: ['vocals', 'guitar', 'piano'], name: 'Lucas Fanchon'},
        { instrument: 'backvoc', name: 'Guillaume & Juliette Fanchon'},
        { instrument: 'violin', name: 'Fanny Haut & Maud Jaworski'},
        { instrument: 'cello', name: 'Hélène Régnier'},
        { instrument: 'sax', name: 'Julien Petit'},
        { instrument: 'guitarel', name: 'Thomas Schmidt'},
        { instrument: ['drums', 'perc', 'glock'], name: 'Alex Tran Van Tuat '},
        { instrument: ['guitarac', 'bass', 'piano'], name: 'Régis Lamora'}
      ],
      imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/2003-lucas-french.jpg',
    },
    { /* 2001 GUY OHRESSER - LA DANSE DES DUNES */ 
      artist: 'Guy Ohresser',
      title: 'La danse des dunes',
      year: '2001',
      type: 'audio',
      tracks: [
        {
          title: 'Eveil',
          url: 'https://dtlck96hf0n6q.cloudfront.net/audio/Guy-eveil.mp3',
          imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/2001-guy-danse.png'
        },
        {
          title: 'Of time and the river',
          url: 'https://dtlck96hf0n6q.cloudfront.net/audio/Guy-oftimeandtheriver.mp3',
          imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/2001-guy-danse.png'
        },
        {
          title: 'Jeux de rivière',
          url: 'https://dtlck96hf0n6q.cloudfront.net/audio/Guy-jeuxderiviere.mp3',
          imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/2001-guy-danse.png'
        },
        {
          title: 'Le doigt manquant',
          url: 'https://dtlck96hf0n6q.cloudfront.net/audio/Guy-ledoigtmanquant.mp3',
          imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/2001-guy-danse.png'
        }
      ],
      credits: [
        { type: 'music', people: 'Guy Ohresser'},
      ],
      personnel: [
        { instrument: ['guitar', 'prog'], name: 'Guy Ohresser'},
        { instrument: 'backvoc', name: 'Caroline Crozat'},
        { instrument: 'cello', name: 'Sophie Gbetro'},
        { instrument: ['sax', 'flute'], name: 'Gérard Delesse'},
        { instrument: ['drums', 'perc'], name: 'Hervé Rouyer'},
        { instrument: ['bass', 'fretless'], name: 'Régis Lamora'}
      ],
      imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/2001-guy-danse.png',
    },
    { /* 2000 LUCAS - JE SUIS BIEN */ 
      artist: 'Lucas et la Mystery Machine',
      title: 'Je suis bien',
      year: '2000',
      credits: [
        { type: ['lyrics', 'music'], people: 'Lucas Fanchon'},
      ],
      personnel: [
        { instrument: ['vocals', 'guitar'], name: 'Lucas Fanchon'},
        { instrument: ['drums', 'perc'], name: 'Alex Tran Van Tuat '},
        { instrument: 'bass', name: 'Régis Lamora'}
      ],
      imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/2000-lucas-bien.jpg',
    },
    { /* 2000 JEAN-PASCAL BOFFO - PARFUM D'ÉTOILES */ 
      artist: 'Jean-Pascal Boffo',
      title: 'Parfum d\'étoiles',
      year: '2000',
      type: 'audio',
      tracks: [
        {
          title: 'Invizible',
          url: 'https://dtlck96hf0n6q.cloudfront.net/audio/JPB-invizible.mp3',
          imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/2000-boffo-parfum.jpg'
        }
      ],
      credits: [
        { type: 'music', people: 'Jean-Pascal Boffo'},
      ],
      personnel: [
        { instrument: ['guitar', 'prog'], name: 'Jean-Pascal Boffo'},
        { instrument: 'backvoc', name: 'Caroline Crozat'},
        { instrument: 'violin', name: 'François Michaud'},
        { instrument: 'sax', name: 'Gérard Delesse'},
        { instrument: 'clarinet', name: 'Vincent Goubert'},
        { instrument: 'synth', name: 'Frédéric Sold'},
        { instrument: 'piano', name: 'Murat Oztürk'},
        { instrument: ['drums', 'perc'], name: 'Hervé Rouyer'},
        { instrument: 'bass', name: 'Vincent Nolot'},
        { instrument: 'fretless', name: 'Régis Lamora'}
      ],
      imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/2000-boffo-parfum.jpg',
    },
    { /* 1998 KAHARO - LES DENTS ROUGES */ 
      artist: 'Kaharo',
      title: 'Les dents rouges',
      year: '1998',
      credits: [
        { type: 'lyrics', people: 'Caroline Crozat'},
        { type: 'music', people: 'Caroline Crozat, Jean-Pascal Boffo, Philippe Jonquel & Séraphin Palmieri'},
      ],
      personnel: [
        { instrument: 'vocals', name: 'Caroline Crozat'},
        { instrument: 'guitar', name: 'Jean-Pascal Boffo'},
        { instrument: 'synth', name: 'Frédéric Sold'},
        { instrument: 'drums', name: 'Hervé Rouyer'},
        { instrument: 'sax', name: 'Julien Petit'},
        { instrument: 'trumpet', name: 'Paul Laveine'},
        { instrument: 'violin', name: 'François Michaud'},
        { instrument: 'bass', name: 'Philippe Jonquel'},
        { instrument: 'fretless', name: 'Régis Lamora'}
      ],
      imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/1998-kaharo.jpg',
    },
    { /* 1998 JEAN-PASCAL BOFFO - VU DU CIEL */ 
      artist: 'Jean-Pascal Boffo',
      title: 'Vu du ciel',
      year: '1998',
      type: 'audio',
      tracks: [
        {
          title: 'Adios',
          url: 'https://dtlck96hf0n6q.cloudfront.net/audio/JPB-adios.mp3',
          imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/1998-boffo-vu.jpeg'
        }
      ],
      credits: [
        { type: 'music', people: 'Jean-Pascal Boffo'},
      ],
      personnel: [
        { instrument: ['guitar', 'prog'], name: 'Jean-Pascal Boffo'},
        { instrument: 'vocals', name: 'Sabrina Fischer'},
        { instrument: 'sax', name: 'Gérard Delesse'},
        { instrument: 'flute', name: 'Nicolas Pourkat'},
        { instrument: 'synth', name: 'Frédéric Sold'},
        { instrument: 'piano', name: 'Murat Oztürk'},
        { instrument: 'perc', name: 'Mickaël Monnin'},
        { instrument: ['drums', 'perc'], name: 'Hervé Rouyer'},
        { instrument: 'doublebass', name: 'Gautier Laurent'},
        { instrument: 'fretless', name: 'Régis Lamora'}
      ],
      imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/1998-boffo-vu.jpeg',
    },
    /*
    Adios
    */
    { /* 2000 LUCAS - BIENVENUE */ 
      artist: 'Lucas et la Mystery Machine',
      title: 'Bienvenue',
      year: '1998',
      credits: [
        { type: ['lyrics', 'music'], people: 'Lucas Fanchon'},
      ],
      personnel: [
        { instrument: ['vocals', 'guitar'], name: 'Lucas Fanchon'},
        { instrument: 'violin', name: 'Jacques Bégot'},
        { instrument: 'cello', name: 'Jennifer Obertan'},
        { instrument: ['drums', 'perc'], name: 'Alex Tran Van Tuat '},
        { instrument: 'bass', name: 'Régis Lamora, Bertrand Allaume'}
      ],
      imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/1998-lucas-bienvenue.jpg',
    },
    { /* MISTER OZ */ 
      artist: 'Mister Oz\'s',
      title: 'Mister Oz\'s big band',
      year: '1998',
      personnel: [
        { instrument: ['direction', 'sax'], name: 'Damien Prudhomme'},
        { instrument: 'sax', name: 'Julien Petit, Jacky Kohn, Raphael Fauquier, Dominique Gatto, Michael Cuvillon'},
        { instrument: 'piano', name: 'Ivan (Fais-moi signe !'},
        { instrument: 'trumpet', name: 'Edouard Romano, Paul Laveine, Patrice Lerech'},
        { instrument: 'trombone', name: 'Olivier Jansen, François Cochet, Geoffroy Maire'},
        { instrument: 'drums', name: 'Alex Tran Van Tuat '},
        { instrument: 'bass', name: 'Herbert Stengele'},
        { instrument: 'guitar', name: 'Régis Lamora'}
      ],
      imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/1998-oz.jpg',
    },
    { /* 1997 GUY OHRESSER - TRAVERSEE */ 
      artist: 'Guy Ohresser',
      title: 'Traversée',
      year: '1997',
      type: 'audio',
      tracks: [
        {
          title: 'Traversée',
          url: 'https://dtlck96hf0n6q.cloudfront.net/audio/Guy-traversee.mp3',
          imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/1997-guy-traversee.png'
        },
        {
          title: 'Comme un bruissement d\'ailes',
          url: 'https://dtlck96hf0n6q.cloudfront.net/audio/Guy-bruissement.mp3',
          imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/1997-guy-traversee.png'
        }
      ],
      credits: [
        { type: 'music', people: 'Guy Ohresser'},
      ],
      personnel: [
        { instrument: ['guitar', 'prog'], name: 'Guy Ohresser'},
        { instrument: ['drums', 'perc'], name: 'Hervé Rouyer'},
        { instrument: ['bass', 'fretless'], name: 'Régis Lamora'}
      ],
      imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/1997-guy-traversee.png',
    },
    { /* 1997 OPENING */ 
      artist: 'Opening Quartet',
      title: 'Demo',
      year: '1997',
      credits: [
        { type: 'music', people: 'Frédéric Sold'},
      ],
      personnel: [
        { instrument: ['piano', 'synth'], name: 'Frédéric Sold'},
        { instrument: 'sax', name: 'Gérard Delesse'},
        { instrument: 'drums', name: 'Michel Altmayer'},
        { instrument: ['bass', 'fretless'], name: 'Régis Lamora'}
      ],
      imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/1997-opening.jpg',
    },
    { /* 1992 LE TOURDION */ 
      artist: 'Le Tourdion',
      title: 'Ça fait vingt ans ',
      year: '1992',
      personnel: [
        { instrument: ['direction', 'synth'], name: 'Vincent Cordel'},
        { instrument: 'sax', name: 'Laurent Bouillé'},
        { instrument: 'guitar', name: 'Philippe Lemmer'},
        { instrument: 'drums', name: 'Sébastien Nicolay'},
        { instrument: ['bass', 'guitar'], name: 'Régis Lamora'}
      ],
      imageUrl: 'https://dtlck96hf0n6q.cloudfront.net/images/1992-tourdion.jpg',
    },
  ];

  return (
    <>
    <SEOHead
      title={t('seo.discography.title')}
      description={t('seo.discography.description')}
    />
      <div className="max-w-6xl mx-auto">
        {discographyData.map((album, index) => (
          <Album key={index} {...album} t={t} />
        ))}
      </div>
    </>
  );
};

export default Discography;