import React, { useState, useRef, useEffect } from 'react';
import { Play, Pause, Volume2, VolumeX, SkipBack, SkipForward } from 'lucide-react';
import { useAudioPlayer } from '../contexts/AudioPlayerContext';

const SecureAudioPlayer = ({ tracks = [], imageUrl = null }) => {
  const { stopAllPlayers } = useAudioPlayer();
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isMuted, setIsMuted] = useState(false);
  const [volume, setVolume] = useState(1);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const audioRef = useRef(null);

  // Empêche le clic droit
  useEffect(() => {
    const handleContextMenu = (e) => e.preventDefault();
    document.addEventListener('contextmenu', handleContextMenu);
    return () => document.removeEventListener('contextmenu', handleContextMenu);
  }, []);

  // Gestion de la durée de l'audio
  useEffect(() => {
    const handleLoadedMetadata = () => {
      setDuration(audioRef.current.duration);
    };
    if (audioRef.current) {
      audioRef.current.addEventListener('loadedmetadata', handleLoadedMetadata);
    }
    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener('loadedmetadata', handleLoadedMetadata);
      }
    };
  }, [currentTrackIndex]);

  // Mise à jour du temps actuel
  useEffect(() => {
    const updateTime = () => {
      if (audioRef.current) {
        setCurrentTime(audioRef.current.currentTime);
      }
    };
    const interval = setInterval(updateTime, 1000);
    return () => clearInterval(interval);
  }, []);

  // Gestion de la fin d'un morceau
  useEffect(() => {
    const handleTrackEnd = () => {
      if (currentTrackIndex < tracks.length - 1) {
        setCurrentTrackIndex(prev => prev + 1);
        if (isPlaying) {
          setTimeout(() => {
            audioRef.current.play();
          }, 100);
        }
      } else {
        setIsPlaying(false);
        setCurrentTrackIndex(0);
      }
    };

    if (audioRef.current) {
      audioRef.current.addEventListener('ended', handleTrackEnd);
    }
    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener('ended', handleTrackEnd);
      }
    };
  }, [currentTrackIndex, tracks.length, isPlaying]);

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      stopAllPlayers(audioRef.current);
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeChange = (e) => {
    const newTime = (e.target.value / 100) * duration;
    audioRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  const toggleMute = () => {
    if (isMuted) {
      audioRef.current.volume = volume;
    } else {
      audioRef.current.volume = 0;
    }
    setIsMuted(!isMuted);
  };

  const handleVolumeChange = (e) => {
    const newVolume = e.target.value / 100;
    audioRef.current.volume = newVolume;
    setVolume(newVolume);
    if (newVolume === 0) {
      setIsMuted(true);
    } else if (isMuted) {
      setIsMuted(false);
    }
  };

  const handleTrackChange = (index) => {
    setCurrentTrackIndex(index);
    setCurrentTime(0);
    if (isPlaying) {
      setTimeout(() => {
        audioRef.current.play();
      }, 100);
    }
  };

  const playNextTrack = () => {
    if (currentTrackIndex < tracks.length - 1) {
      handleTrackChange(currentTrackIndex + 1);
    }
  };

  const playPreviousTrack = () => {
    if (currentTrackIndex > 0) {
      handleTrackChange(currentTrackIndex - 1);
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  // Si pas de pistes ou tableau vide, on retourne null
  if (!tracks || tracks.length === 0) return null;

  // Si une seule URL est passée, on la convertit en tableau de pistes
  const normalizedTracks = typeof tracks === 'string' 
    ? [{ url: tracks, title: 'Track 1' }] 
    : tracks;

  return (
    <div className="bg-black/30 rounded-lg p-4 select-none" onContextMenu={e => e.preventDefault()}>
      {normalizedTracks[currentTrackIndex]?.imageUrl && (
        <img 
          src={normalizedTracks[currentTrackIndex]?.imageUrl} // Point d'interrogation
          alt={normalizedTracks[currentTrackIndex].title || 'Album cover'}
          className="w-4/5 max-h-[600px] h-auto mx-auto rounded-lg mb-4 object-contain"
          onContextMenu={e => e.preventDefault()}
          draggable="false"
        />
      )}

      <audio
        ref={audioRef}
        src={normalizedTracks[currentTrackIndex]?.url}
        preload="metadata"
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
      />

      {/* Titre actuel */}
      <div className="text-white font-medium mb-4">
        {normalizedTracks[currentTrackIndex]?.title}
      </div>

      {/* Contrôles */}
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-2">
            <button
              onClick={playPreviousTrack}
              disabled={currentTrackIndex === 0}
              className={`w-8 h-8 flex items-center justify-center rounded-full transition-colors
                ${currentTrackIndex === 0 
                  ? 'text-gray-500 cursor-not-allowed' 
                  : 'text-white hover:text-orange-500'
                }`}
              aria-label="Previous track"
            >
              <SkipBack size={18} />
            </button>

            <button
              onClick={togglePlay}
              className="w-10 h-10 flex items-center justify-center bg-orange-500 hover:bg-orange-600 rounded-full transition-colors"
              aria-label={isPlaying ? "Pause" : "Play"}
            >
              {isPlaying ? <Pause size={20} /> : <Play size={20} />}
            </button>

            <button
              onClick={playNextTrack}
              disabled={currentTrackIndex === normalizedTracks.length - 1}
              className={`w-8 h-8 flex items-center justify-center rounded-full transition-colors
                ${currentTrackIndex === normalizedTracks.length - 1 
                  ? 'text-gray-500 cursor-not-allowed' 
                  : 'text-white hover:text-orange-500'
                }`}
              aria-label="Next track"
            >
              <SkipForward size={18} />
            </button>
          </div>

          <div className="flex-1">
            <div className="relative h-2 bg-gray-700 rounded-full">
              <input
                type="range"
                min="0"
                max="100"
                value={(currentTime / duration) * 100 || 0}
                onChange={handleTimeChange}
                className="absolute w-full h-full opacity-0 cursor-pointer"
                style={{ WebkitAppearance: 'none' }}
              />
              <div
                className="absolute h-full bg-orange-500 rounded-full"
                style={{ width: `${(currentTime / duration) * 100 || 0}%` }}
              />
            </div>
            <div className="flex justify-between text-xs mt-1 text-gray-400">
              <span>{formatTime(currentTime)}</span>
              <span>{formatTime(duration)}</span>
            </div>
          </div>

          <div className="flex items-center gap-2">
            <button
              onClick={toggleMute}
              className="text-white hover:text-orange-500 transition-colors"
              aria-label={isMuted ? "Unmute" : "Mute"}
            >
              {isMuted ? <VolumeX size={20} /> : <Volume2 size={20} />}
            </button>
            <div className="relative w-20">
              <input
                type="range"
                min="0"
                max="100"
                value={isMuted ? 0 : volume * 100}
                onChange={handleVolumeChange}
                className="w-full h-1 bg-gray-700 rounded-full appearance-none cursor-pointer"
                style={{
                  background: `linear-gradient(to right, #f97316 0%, #f97316 ${
                    isMuted ? 0 : volume * 100
                  }%, #374151 ${isMuted ? 0 : volume * 100}%, #374151 100%)`
                }}
              />
            </div>
          </div>
        </div>

        {/* Liste des pistes */}
        {normalizedTracks.length > 1 && (
          <div className="mt-0 space-y-0.1">
            {normalizedTracks.map((track, index) => (
              <button
                key={index}
                onClick={() => handleTrackChange(index)}
                className={`w-full text-left px-2 py-1 rounded transition-colors ${
                  currentTrackIndex === index
                    ? 'bg-orange-500/20 text-orange-500'
                    : 'hover:bg-white/5 text-gray-300'
                }`}
              >
                {track.title}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SecureAudioPlayer;