import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { AudioPlayerProvider } from './contexts/AudioPlayerContext';
import { LanguageProvider } from './contexts/LanguageContext';
import App from './App';

// Fonction réutilisable pour créer l'arbre de composants App
export function createApp(options = {}) {
  const {
    helmetContext = {},
    initialLanguage,
    setLanguage = () => {},
  } = options;

  return (
    <React.StrictMode>
      <HelmetProvider context={helmetContext}>
        <AudioPlayerProvider>
          <LanguageProvider initialLanguage={initialLanguage} setLanguage={setLanguage}>
            <App />
          </LanguageProvider>
        </AudioPlayerProvider>
      </HelmetProvider>
    </React.StrictMode>
  );
}