// Layout constants
export const LAYOUT_CONSTANTS = {
  HEADER_HEIGHT: 160,
  MAX_CONTENT_WIDTH: 1024,
};

// Navigation links
export const NAV_LINKS = ['home', 'composition', 'courses', 'career', 'discography', 'links', 'contact'];

// Social media links
export const SOCIAL_LINKS = {
  youtube: 'https://www.youtube.com/@regislamora',
  instagram: 'http://instagram.com/regislamora', 
  facebook: 'http://www.facebook.com/regislamora',
  soundcloud: 'http://soundcloud.com/regislamora'
};