export const translations = {
  en: {
    navigation: {
      home: 'Home',
      composition: 'Composition',
      courses: 'Courses',
      career: 'Career',
      discography: 'Discography',
      links: 'Links',
      contact: 'Contact'
    },
    common: {
      discography: "Discography"
    },
    newsletter: {
      title: 'Subscribe to my newsletter',
      placeholder: 'Your email address',
      submit: 'Subscribe'
    },
    home: {
      tagline: 'Guitar, bass',
      welcome: 'Welcome',
      introduction: 'Musician and teacher, I compose, arrange and perform on stage and in studio. I teach guitar, bass, ear training and music theory online.',
      latestReleases: 'Latest Releases',
      activities: 'Activities',
      activity1: 'Composition and arrangements',
      activity2: 'Stage and studio musician',
      activity3: 'Music teaching',
      latestNews: 'Latest News',
      latestTrack: 'Latest Track',
      playerPlaceholder: 'Audio Player',
      onlineLessons: 'Online Music Lessons',
      lessonsDescription: 'Guitar, bass, ear training, rhythm, and music theory lessons via video call. First lesson is free!',
      learnMore: 'Learn More'
    },
    composition: {
      listen: "Listen",
      musicians: "Musicians",
      descriptions: {
        donnaSteps: "Adaptation of Donna Lee (Charlie Parker) over Giant Steps (John Coltrane) chord changes",
        laQuestion: "The question being what is the question...",
        gareBoisColombes: "A light waltz on piano about a place conducive to daydreaming (especially when trains are no longer running)"
      },
      instruments: {
        accordion: "Accordion",
        drums: "Drums",
        bass: "Bass",
        violin: "Violin"
      }
    },
    contact: {
      title: 'Contact Me',
      name: 'Name',
      email: 'Email',
      message: 'Message',
      submit: 'Send Message',
      sending: 'Sending...',
      success: 'Message sent successfully! I will get back to you soon.',
      error: 'An error occurred. Please try again.',
      verifyHuman: "Human verification",
      captchaRequired: "Please complete the verification",
      newCaptcha: "↻ New calculation",
      enterResult: "Result",
      socialMedia: 'Find me on social media'
    },
    career: {
      scene: {
        title: 'Stage Experience',
        concerts: 'Concerts and Tours',
        tv: 'TV Shows',
        collaborations: 'Other Collaborations'
      },
      experience: {
        title: 'Professional Experience',
        composition: 'Composition',
        arrangements: 'Arrangements',
        studio: "Studio",
        studio_description: "Guitar and bass, see",
        productions: 'Productions and Realizations'
      },
      formation: {
        title: 'Education',
        other: 'Other Training'
      },
      teaching: {
        title: 'Teaching Experience',
        current: 'Current Position',
        previous: 'Previous Positions'
      }
    },
      discography: {
        credits: 'Credits',
        musicians: 'Musicians'
      },
      instruments: {
        vocals: 'Vocals',
        backvoc: 'Backing vocals',
        synth: 'Synthesizer',
        drums: 'Drums',
        perc: 'Percussion',
        piano: 'Piano',
        guitar: 'Guitar',
        guitarel: 'Electric guitar',
        guitarac: 'Acoustic guitar',
        guitarlead: 'Lead guitar',
        bass: 'Bass',
        fretless: 'Fretless bass',
        doublebass: 'Double bass',
        prog: 'Programming',
        balafon: 'Balafon',
        congas: 'Congas',
        djembe: 'Djembe',
        cajon: 'Cajon',
        glock: 'Glockenspiel',
        breath: 'Breath',
        flute: 'Flute',
        cello: 'Cello',
        violin: 'Violin',
        sax: 'Sax',
        trumpet: 'Trumpet',
        clarinet: 'Clarinet',
        multigt: 'Prog drums, keys and brass',
        direction: 'Lead'
      },
      credits: {
        direction: 'Video direction',
        lyrics: 'Lyrics',
        music: 'Music',
        production: 'Production',
        arrangement: 'Arrangement',
        AD: 'Artistic direction',
        mixing: 'Mixing',
        mastering: 'Mastering',
        and: ','
      },
      errors: {
        pageNotFound: "Page not found",
        backToHome: "Back to home page"
      },
      seo: {
        home: { 
          title: "Régis Lamora - Guitar, bass, composition, teaching", 
          description: "Guitarist, bassist and music teacher. Online lessons, composition and studio work. Learn guitar, bass, ear training, rhythm and music theory." 
        },
        composition: { 
          title: "Régis Lamora's Compositions - Original Music", 
          description: "Original compositions, arrangements and musical productions. Jazz, classical and contemporary works." 
        },
        courses: { 
          title: "netMusicien: Music Lessons with Régis Lamora - Guitar, Bass & Theory", 
          description: "Online music lessons: guitar, bass, ear training, rhythm and music theory. First lesson free. Extensive professional teaching experience." 
        },
        career: { 
          title: "Régis Lamora - Career & Professional Experience", 
          description: "Professional background, stage experience, studio work and music teaching." 
        },
        discography: { 
          title: "Régis Lamora - Discography & Musical Works", 
          description: "Complete discography, recordings, collaborations and studio productions." 
        },
        links: { 
          title: "Régis Lamora - Professional Network & Resources", 
          description: "Musical collaborations, professional network and useful resources." 
        },
        contact: { 
          title: "Contact Régis Lamora - Music Lessons & Collaboration", 
          description: "Contact me for music lessons, collaboration or any professional inquiry." 
        }
      }
  },
  fr: {
    navigation: {
      home: 'Accueil',
      composition: 'Composition',
      courses: 'Cours',
      career: 'Parcours',
      discography: 'Discographie',
      links: 'Liens',
      contact: 'Contact'
    },
    common: {
      discography: "Discographie"
    },
    newsletter: {
      title: 'Inscrivez-vous à ma newsletter',
      placeholder: 'Votre adresse email',
      submit: "S'inscrire"
    },
    home: {
      tagline: 'Guitare, basse',
      welcome: 'Bienvenue',
      introduction: 'Musicien et enseignant, je compose, arrange et joue sur scène et en studio. Je donne des cours de guitare, basse, ear training et théorie musicale en ligne.',
      latestReleases: 'Dernières sorties',
      activities: 'Activités',
      activity1: 'Composition et arrangements',
      activity2: 'Musicien scène et studio',
      activity3: 'Enseignement musical',
      latestNews: 'Actualités',
      latestTrack: 'Dernier morceau',
      playerPlaceholder: 'Lecteur Audio',
      onlineLessons: 'Cours de Musique en Ligne',
      lessonsDescription: 'Cours de guitare, basse, ear training, rythme et théorie musicale en visio. Premier cours gratuit !',
      learnMore: 'En savoir plus'
    },
    composition: {
      listen: "Écouter",
      musicians: "Musiciens",
      descriptions: {
        donnaSteps: "Adaptation du thème de Donna Lee (Charlie Parker) sur la grille de Giant steps (John Coltrane)",
        laQuestion: "La question étant de savoir quelle est la question...",
        gareBoisColombes: "Petit 3 temps au piano sur un endroit propice à la rêverie (surtout à l'heure où les trains ne passent plus)"
      },
      instruments: {
        accordion: "Accordéon",
        drums: "Batterie",
        bass: "Basse",
        violin: "Violon"
      }
    },
    contact: {
      title: 'Contactez-moi',
      name: 'Nom',
      email: 'Email',
      message: 'Message',
      submit: 'Envoyer',
      sending: 'Envoi en cours...',
      success: 'Message envoyé avec succès ! Je vous répondrai bientôt.',
      error: 'Une erreur est survenue. Veuillez réessayer.',
      verifyHuman: "Vérification humaine",
      captchaRequired: "Veuillez compléter la vérification",
      newCaptcha: "↻ Nouveau calcul",
      enterResult: "Résultat",
      socialMedia: 'Retrouvez-moi sur les réseaux sociaux'
    },
    career: {
      scene: {
        title: 'Scène',
        concerts: 'Concerts et tournées',
        tv: 'Plateaux TV',
        collaborations: 'Autres collaborations'
      },
      experience: {
        title: 'Expérience professionnelle',
        composition: 'Composition',
        arrangements: 'Arrangements',
        studio: "Studio",
        studio_description: "Guitare et basse, voir page",
        productions: 'Réalisations'
      },
      formation: {
        title: 'Formation',
        other: 'Autres formations'
      },
      teaching: {
        title: 'Enseignement',
        current: 'Poste actuel',
        previous: 'Postes précédents'
      }
    },
    discography: {
      credits: 'Crédits',
      musicians: 'Musiciens'
    },
    instruments: {
      vocals: 'Chant',
      backvoc: 'Chœurs',
      synth: 'Synthés',
      drums: 'Batterie',
      perc: 'Percussions',
      piano: 'Piano',
      guitar: 'Guitare',
      guitarel: 'Guitare électrique',
      guitarac: 'Guitare acoustique',
      guitarlead: 'Guitare lead',
      bass: 'Basse',
      fretless: 'Basse fretless',
      doublebass: 'Contrebasse',
      prog: 'Prog',
      balafon: 'Balafon',
      congas: 'Congas',
      djembe: 'Djembé',
      cajon: 'Cajon',
      glock: 'Glockenspiel',
      breath: 'Souffle',
      flute: 'Flûte traversière',
      cello: 'Violoncelle',
      violin: 'Violon',
      sax: 'Sax',
      clarinet: 'Clarinette',
      trumpet: 'Trompette',
      multigt: 'Prog batterie, claviers et cuivres',
      direction: 'Direction'
    },
    credits: {
      direction: 'Réalisation du clip',
      lyrics: 'Paroles',
      music: 'Musique',
      production: 'Production',
      arrangement: 'Arrangement',
      AD: 'Réalisation',
      mixing: 'Mixage',
      mastering: 'Mastering',
      and: ','
    },
    errors: {
      pageNotFound: "Page non trouvée",
      backToHome: "Retour à l'accueil"
    },
    seo: {
      home: { 
        title: "Régis Lamora - Guitare, basse, composition, enseignement", 
        description: "Guitariste, bassiste et professeur de musique. Cours en ligne, composition et travail en studio. Apprenez la guitare, la basse, l'ear training, le rythme et la théorie musicale." 
      },
      composition: { 
        title: "Compositions de Régis Lamora - Musique Originale", 
        description: "Compositions originales, arrangements et productions musicales. Œuvres jazz, classiques et contemporaines." 
      },
      courses: { 
        title: "netMusicien: Cours de Musique avec Régis Lamora - Guitare, Basse & Théorie", 
        description: "Cours de musique en ligne : guitare, basse, ear training, rythme et théorie musicale. Premier cours gratuit. Longue expérience professionnelle de l'enseignement." 
      },
      career: { 
        title: "Régis Lamora - Parcours & Expérience Professionnelle", 
        description: "Parcours professionnel, expérience scénique, travail en studio et enseignement musical." 
      },
      discography: { 
        title: "Régis Lamora - Discographie & Œuvres Musicales", 
        description: "Discographie complète, enregistrements, collaborations et productions en studio." 
      },
      links: { 
        title: "Régis Lamora - Réseau Professionnel & Ressources", 
        description: "Collaborations musicales, réseau professionnel et ressources utiles." 
      },
      contact: { 
        title: "Contactez Régis Lamora - Cours de Musique & Collaboration", 
        description: "Contactez-moi pour des cours de musique, collaboration ou toute demande professionnelle." 
      }
    }
  },
  es: {
    navigation: {
      home: 'Inicio',
      composition: 'Composición',
      courses: 'Cursos',
      career: 'Trayectoria',
      discography: 'Discografía',
      links: 'Enlaces',
      contact: 'Contacto'
    },
    common: {
      discography: "Discografía"
    },
    newsletter: {
      title: 'Suscríbete a mi newsletter',
      placeholder: 'Tu dirección de email',
      submit: 'Suscribirse'
    },
    home: {
      tagline: 'Guitarra, bajo',
      welcome: 'Bienvenido',
      introduction: 'Músico y profesor, compongo, arreglo y toco en escena y en estudio. Doy clases de guitarra, bajo, entrenamiento auditivo y teoría musical en línea.',
      latestReleases: 'Últimos lanzamientos',
      activities: 'Actividades',
      activity1: 'Composición y arreglos',
      activity2: 'Músico de escenario y estudio',
      activity3: 'Enseñanza musical',
      latestNews: 'Últimas Noticias',
      latestTrack: 'Última Pista',
      playerPlaceholder: 'Reproductor de Audio',
      onlineLessons: 'Clases de Música Online',
      lessonsDescription: 'Clases de guitarra, bajo, entrenamiento auditivo, ritmo y teoría musical por videollamada. ¡Primera clase gratis!',
      learnMore: 'Más información'
    },
    composition: {
      listen: "Escuchar",
      musicians: "Músicos",
      descriptions: {
        donnaSteps: "Adaptación del tema Donna Lee (Charlie Parker) sobre la progresión de Giant Steps (John Coltrane)",
        laQuestion: "La pregunta es cuál es la pregunta...",
        gareBoisColombes: "Un vals ligero al piano sobre un lugar propicio para soñar despierto (sobre todo cuando ya no pasan trenes)"
      },
      instruments: {
        accordion: "Acordeón",
        drums: "Batería",
        bass: "Bajo",
        violin: "Violín"
      }
    },
    contact: {
      title: 'Contáctame',
      name: 'Nombre',
      email: 'Email',
      message: 'Mensaje',
      submit: 'Enviar mensaje',
      sending: 'Enviando...',
      success: '¡Mensaje enviado con éxito! Te responderé pronto.',
      error: 'Ocurrió un error. Por favor, inténtalo de nuevo.',
      verifyHuman: "Verificación humana",
      captchaRequired: "Por favor complete la verificación",
      newCaptcha: "↻ Nuevo cálculo",
      enterResult: "Resultado",
      socialMedia: 'Encuéntrame en las redes sociales'
    },
    career: {
      scene: {
        title: 'Escenario',
        concerts: 'Conciertos y giras',
        tv: 'Programas de TV',
        collaborations: 'Otras colaboraciones'
      },
      experience: {
        title: 'Experiencia profesional',
        composition: 'Composición',
        arrangements: 'Arreglos',
        studio: "Estudio",
        studio_description: "Guitarra y bajo, ver",
        productions: 'Producciones y realizaciones'
      },
      formation: {
        title: 'Formación',
        other: 'Otras formaciones'
      },
      teaching: {
        title: 'Experiencia docente',
        current: 'Puesto actual',
        previous: 'Puestos anteriores'
      }
    },
    discography: {
      credits: 'Créditos',
      musicians: 'Músicos'
    },
    instruments: {
      vocals: 'Voz',
      backvoc: 'Coros',
      synth: 'Sintetizador',
      drums: 'Batería',
      perc: 'Percusión',
      piano: 'Piano',
      guitar: 'Guitarra',
      guitarel: 'Guitarra eléctrica',
      guitarac: 'Guitarra acústica',
      guitarlead: 'Guitarra solista',
      bass: 'Bajo',
      fretless: 'Bajo fretless',
      doublebass: 'Contrabajo',
      prog: 'Programación',
      balafon: 'Balafón',
      congas: 'Congas',
      djembe: 'Djembe',
      cajon: 'Cajón',
      glock: 'Glockenspiel',
      breath: 'Aliento',
      flute: 'Flauta travesera',
      cello: 'Violonchelo',
      violin: 'Violín',
      sax: 'Saxofón',
      clarinet: 'Clarinete',
      trumpet: 'Trompeta',
      multigt: 'Prog batería, teclados y metales',
      direction: 'Dirección'
    },
    credits: {
      direction: 'Realizacíon del videoclip',
      lyrics: 'Letra',
      music: 'Música',
      production: 'Producción',
      arrangement: 'Arreglos',
      AD: 'Dirección artística',
      mixing: 'Mezcla',
      mastering: 'Mastering',
      and: ','
    },
    errors: {
      pageNotFound: "Página no encontrada",
      backToHome: "Volver a la página de inicio"
    },
    seo: {
      home: { 
        title: "Régis Lamora - Guitarra, bajo, composición, enseñanza", 
        description: "Guitarrista, bajista y profesor de música. Clases en línea, composición y trabajo en estudio. Aprende guitarra, bajo, entrenamiento auditivo, ritmo y teoría musical." 
      },
      composition: { 
        title: "Composiciones de Régis Lamora - Música Original", 
        description: "Composiciones originales, arreglos y producciones musicales. Obras de jazz, clásicas y contemporáneas." 
      },
      courses: { 
        title: "netMusicien: Clases de Música con Régis Lamora - Guitarra, Bajo y Teoría", 
        description: "Clases de música en línea: guitarra, bajo, entrenamiento auditivo, ritmo y teoría musical. Primera clase gratuita. Amplia experiencia profesional en la enseñanza." 
      },
      career: { 
        title: "Régis Lamora - Trayectoria y Experiencia Profesional", 
        description: "Trayectoria profesional, experiencia escénica, trabajo en estudio y enseñanza musical." 
      },
      discography: { 
        title: "Régis Lamora - Discografía y Obras Musicales", 
        description: "Discografía completa, grabaciones, colaboraciones y producciones en estudio." 
      },
      links: { 
        title: "Régis Lamora - Red Profesional y Recursos", 
        description: "Colaboraciones musicales, red profesional y recursos útiles." 
      },
      contact: { 
        title: "Contactar con Régis Lamora - Clases de Música y Colaboración", 
        description: "Contacta conmigo para clases de música, colaboración o cualquier consulta profesional." 
      }
    }
  }
};

export const AVAILABLE_LANGUAGES = ['en', 'fr', 'es'];