import React, { useState, useEffect } from 'react';
import { getRoutePath } from './utils/paths';
import { 
  createBrowserRouter, 
  RouterProvider,
  BrowserRouter,
  Routes,
  Route,
  Navigate 
} from 'react-router-dom';
import { LanguageProvider } from './contexts/LanguageContext';
import { AudioPlayerProvider } from './contexts/AudioPlayerContext';
import { getInitialLanguage } from './utils/language';
import Layout from './components/Layout';
import Home from './pages/Home';
import Composition from './pages/Composition';
import Courses from './pages/Courses';
import Career from './pages/Career';
import Discography from './pages/Discography';
import Links from './pages/Links';
import Contact from './pages/Contact';
import NotFound from './pages/NotFound';

// Routes configuration shared between all routing approaches
const routeConfig = [
  { path: "", element: <Home /> },
  { path: "composition", element: <Composition /> },
  { path: "courses", element: <Courses /> },
  { path: "career", element: <Career /> },
  { path: "discography", element: <Discography /> },
  { path: "links", element: <Links /> },
  { path: "contact", element: <Contact /> },
  { path: "*", element: <NotFound /> }
];

export default function App() {
  const [language, setLanguage] = useState(getInitialLanguage());
  const [isClient, setIsClient] = useState(false);
  
  // Only set isClient to true on client-side after initial render
  useEffect(() => {
    console.log("App mounted - client-side hydration active");
    setIsClient(true);
  }, []);

  // Check if we're in a browser environment
  const isBrowser = typeof window !== 'undefined';
  
  // Use Data Router API (RouterProvider + createBrowserRouter) on client-side
  if (isClient && isBrowser) {
    const router = createBrowserRouter([
      {
        // Redirection de la racine vers la langue détectée
        path: "/",
        element: <Navigate to={getRoutePath(`${language}`)} replace />
      },
      {
        // Routes avec langue
        path: "/:lang",
        element: <Layout />,
        children: routeConfig,
      },
      {
        basename: import.meta.env.VITE_BASE_URL || ''
      }  
    ]);

    return (
      <AudioPlayerProvider>
        <LanguageProvider initialLanguage={language} setLanguage={setLanguage}>
          <RouterProvider router={router} />
        </LanguageProvider>
      </AudioPlayerProvider>
    );
  }

  // Use traditional Router + Routes/Route for SSR and initial render
  return (
    <AudioPlayerProvider>
      <LanguageProvider initialLanguage={language} setLanguage={setLanguage}>
        <BrowserRouter basename={import.meta.env.VITE_BASE_URL || ''}>
          <Routes>
            <Route 
              path="/" 
              element={<Navigate to={`/${language}`} replace />} 
            />
            <Route path="/:lang" element={<Layout />}>
              {routeConfig.map((route) => (
                <Route 
                  key={route.path || 'index'} 
                  path={route.path ? `/:lang/${route.path}` : '/:lang'} 
                  element={route.element} 
                />
              ))}
            </Route>
          </Routes>
        </BrowserRouter>
      </LanguageProvider>
    </AudioPlayerProvider>
  );
}